
import debounce from 'lodash.debounce'
import { getMostPertinentSuggestions } from '~/helpers/searchSuggestions'
import fullheight from '~/mixins/fullheight'

export default {
  mixins: [fullheight],

  props: {
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    darkTheme: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isSearchExpanded: false,
      search: '',
      suggestions: [],
      currentlySearching: undefined, // string representing the current search term (used to avoid race conditions in the debounced function)
      editorialEntries: undefined, // must be initialized as undefined and not an empty array, to know if data has been fetched or not (even if empty)
      isLoading: false,
    }
  },

  beforeMount() {
    // prefill with currently search term, if any
    this.search = this.$route.query.q || ''
  },

  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          window.addEventListener('focusin', this.close, false)
          window.addEventListener('keydown', this.close, false)
        } else {
          window.removeEventListener('focusin', this.close, false)
          window.removeEventListener('keydown', this.close, false)
        }
      },
      { threshold: 0 }
    )

    this.observer.observe(this.$refs.searchDialog)
  },

  created() {
    this.$nuxt.$on('openSearchMenu', () => {
      this.showSearchDialog()
    })
  },

  beforeDestroy() {
    this.$nuxt.$off('openSearchMenu')

    // Making sure body never keeps the overflow-hidden class when switching pages
    this.$store.dispatch('setAsFullscreenComponent', false)
  },

  beforeUnmount() {
    this.observer.disconnect()
  },

  methods: {
    handleSearch() {
      this.$router.push(
        this.localePath({
          name: 'search',
          query: { q: this.search },
        })
      )

      this.hideSearchDialog()
    },

    resetSearch() {
      this.search = ''
      this.suggestions = []
      this.$refs.search.focus()
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.hideSearchDialog()
      }

      if (e.key === 'Escape') {
        this.hideSearchDialog()
      }
    },

    showSearchDialog() {
      this.isSearchExpanded = true

      if (!this.editorialEntries) {
        // This is the first time search is opened, so we fetch the editorial entries (only once, though)
        this.fetchEditorialEntries()
      }

      this.$nextTick(() => {
        this.$refs.search.focus()

        // JS tricks: Putting cursor at end of input
        this.$refs.search.value = ''
        this.$refs.search.value = this.search

        if (this.search) {
          // Force update of suggestions
          this.updateSuggestions()
        }
      })

      this.$store.dispatch('setAsFullscreenComponent', true)
    },

    hideSearchDialog() {
      this.isSearchExpanded = false

      this.$store.dispatch('setAsFullscreenComponent', false)
      // A11Y: Focus back to dialog trigger button
      this.$refs.searchButton.focus()
    },

    updateSuggestions: debounce(async function () {
      const search = this.search.trim()
      if (search.length === 0) {
        this.suggestions = []
        return
      }

      this.currentlySearching = search
      const suggestions = await this.$hummingbird.catalog
        .autocomplete(search)
        .then(res => getMostPertinentSuggestions(res.data, search, 7))
        .catch(() => [])

      if (this.currentlySearching === search) {
        // This check is necessary to avoid race conditions due to debouncing
        // (e.g. an older API request responding after a newer one)
        this.suggestions = suggestions
      }
    }, 500),

    async fetchEditorialEntries() {
      this.isLoading = true

      const editorialEntriesItems = (
        await this.$hummingbird.catalog
          .getSearchEditorialEntries()
          .then(res => res.data.editorial_entries)
          .catch(() => [])
      )
        .filter(entry => {
          // Only "selection_entry" / "promotional_entry" types are supported for now (a list of books)
          // Support for "series_entry" (a list of magazines) will be added later
          return ['selection_entry', 'promotional_entry'].includes(entry.type)
        })
        .map(entry => {
          // We have to normalize that, since the response is polymorphic
          if (entry.type === 'selection_entry') {
            return entry.selection_entry.selection
          } else {
            return entry.promotional_entry.selection
          }
        })

      this.editorialEntries = await Promise.all(
        editorialEntriesItems.map(async selection => {
          const products = await this.$hummingbird.catalog
            .getSelectionProducts(selection.id, 0, 6)
            .then(res => res.data.products)
            .catch(() => [])

          return {
            selection,
            // FIXME: Ensure we have no more that 6 products... API has a bug where it returns one extra or one less for some languages
            // @see https://frescano.slack.com/archives/C056ZGJBQ8M/p1692719624015779
            products: products.slice(0, 6),
          }
        })
      )

      this.isLoading = false
    },
  },
}
