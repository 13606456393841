import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _afe17a58 = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _7559d404 = () => interopDefault(import('../pages/audiobooks.vue' /* webpackChunkName: "pages/audiobooks" */))
const _1714966b = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _175c24c6 = () => interopDefault(import('../pages/download-apps.vue' /* webpackChunkName: "pages/download-apps" */))
const _9962ed70 = () => interopDefault(import('../pages/e-books.vue' /* webpackChunkName: "pages/e-books" */))
const _71c1348e = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _3466728f = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _e29c0136 = () => interopDefault(import('../pages/magazines.vue' /* webpackChunkName: "pages/magazines" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _cc080460 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _52e40422 = () => interopDefault(import('../pages/user-terms.vue' /* webpackChunkName: "pages/user-terms" */))
const _3dbaa13a = () => interopDefault(import('../pages/action/login.vue' /* webpackChunkName: "pages/action/login" */))
const _ff35193e = () => interopDefault(import('../pages/categories/books.vue' /* webpackChunkName: "pages/categories/books" */))
const _025be3d4 = () => interopDefault(import('../pages/categories/magazines.vue' /* webpackChunkName: "pages/categories/magazines" */))
const _32250762 = () => interopDefault(import('../pages/profile/change-email.vue' /* webpackChunkName: "pages/profile/change-email" */))
const _7d7cc1b8 = () => interopDefault(import('../pages/profile/change-password.vue' /* webpackChunkName: "pages/profile/change-password" */))
const _b8680aee = () => interopDefault(import('../pages/profile/login.vue' /* webpackChunkName: "pages/profile/login" */))
const _422cc404 = () => interopDefault(import('../pages/profile/login-partner.vue' /* webpackChunkName: "pages/profile/login-partner" */))
const _4e762396 = () => interopDefault(import('../pages/profile/order-history.vue' /* webpackChunkName: "pages/profile/order-history" */))
const _48375a6e = () => interopDefault(import('../pages/profile/profile.vue' /* webpackChunkName: "pages/profile/profile" */))
const _762958a9 = () => interopDefault(import('../pages/profile/quick-login.vue' /* webpackChunkName: "pages/profile/quick-login" */))
const _9c2d48f2 = () => interopDefault(import('../pages/profile/read-and-listen-again.vue' /* webpackChunkName: "pages/profile/read-and-listen-again" */))
const _4a835bd8 = () => interopDefault(import('../pages/profile/receipt.vue' /* webpackChunkName: "pages/profile/receipt" */))
const _4e9bae6e = () => interopDefault(import('../pages/profile/registration.vue' /* webpackChunkName: "pages/profile/registration" */))
const _48288f2c = () => interopDefault(import('../pages/profile/reset-password/index.vue' /* webpackChunkName: "pages/profile/reset-password/index" */))
const _4ddc2450 = () => interopDefault(import('../pages/register/change-success.vue' /* webpackChunkName: "pages/register/change-success" */))
const _68ec2670 = () => interopDefault(import('../pages/register/dimoco/index.vue' /* webpackChunkName: "pages/register/dimoco/index" */))
const _57f75188 = () => interopDefault(import('../pages/register/get-started.vue' /* webpackChunkName: "pages/register/get-started" */))
const _8ecc085a = () => interopDefault(import('../pages/register/ideal.vue' /* webpackChunkName: "pages/register/ideal" */))
const _4ad6960c = () => interopDefault(import('../pages/register/magic-link.vue' /* webpackChunkName: "pages/register/magic-link" */))
const _93317f08 = () => interopDefault(import('../pages/register/payment-mode.vue' /* webpackChunkName: "pages/register/payment-mode" */))
const _b688108c = () => interopDefault(import('../pages/register/payment-success.vue' /* webpackChunkName: "pages/register/payment-success" */))
const _7372f165 = () => interopDefault(import('../pages/register/paypal.vue' /* webpackChunkName: "pages/register/paypal" */))
const _5b309b42 = () => interopDefault(import('../pages/register/planform.vue' /* webpackChunkName: "pages/register/planform" */))
const _7e71f56e = () => interopDefault(import('../pages/register/planinfo.vue' /* webpackChunkName: "pages/register/planinfo" */))
const _d71b91ea = () => interopDefault(import('../pages/register/returning-customer.vue' /* webpackChunkName: "pages/register/returning-customer" */))
const _c23834ce = () => interopDefault(import('../pages/register/sofort.vue' /* webpackChunkName: "pages/register/sofort" */))
const _55f3e3bc = () => interopDefault(import('../pages/register/subscription-picker.vue' /* webpackChunkName: "pages/register/subscription-picker" */))
const _5f06e7e3 = () => interopDefault(import('../pages/register/trustly.vue' /* webpackChunkName: "pages/register/trustly" */))
const _0cc93240 = () => interopDefault(import('../pages/register/welcome.vue' /* webpackChunkName: "pages/register/welcome" */))
const _83d9ce54 = () => interopDefault(import('../pages/subscriptions_faq/consumed-hours.vue' /* webpackChunkName: "pages/subscriptions_faq/consumed-hours" */))
const _3f79a5ef = () => interopDefault(import('../pages/subscriptions_faq/logged-out.vue' /* webpackChunkName: "pages/subscriptions_faq/logged-out" */))
const _3ea7650f = () => interopDefault(import('../pages/in-app/launch.vue' /* webpackChunkName: "pages/in-app/launch" */))
const _653dcff6 = () => interopDefault(import('../pages/launch/nextory-app.vue' /* webpackChunkName: "pages/launch/nextory-app" */))
const _77ee6e9f = () => interopDefault(import('../pages/account/subscription/update.vue' /* webpackChunkName: "pages/account/subscription/update" */))
const _7fda1bf0 = () => interopDefault(import('../pages/profile/cancel/disclaimer.vue' /* webpackChunkName: "pages/profile/cancel/disclaimer" */))
const _3f90cbe6 = () => interopDefault(import('../pages/profile/cancel/downgrade-success.vue' /* webpackChunkName: "pages/profile/cancel/downgrade-success" */))
const _7c48b424 = () => interopDefault(import('../pages/profile/cancel/extend-trial-success.vue' /* webpackChunkName: "pages/profile/cancel/extend-trial-success" */))
const _e8f718d2 = () => interopDefault(import('../pages/profile/cancel/offer.vue' /* webpackChunkName: "pages/profile/cancel/offer" */))
const _1dce11aa = () => interopDefault(import('../pages/profile/cancel/reasons.vue' /* webpackChunkName: "pages/profile/cancel/reasons" */))
const _cda3bd84 = () => interopDefault(import('../pages/profile/cancel/revert-cancel.vue' /* webpackChunkName: "pages/profile/cancel/revert-cancel" */))
const _57542466 = () => interopDefault(import('../pages/profile/cancel/stay-another-month-success.vue' /* webpackChunkName: "pages/profile/cancel/stay-another-month-success" */))
const _cd6c5e04 = () => interopDefault(import('../pages/profile/cancel/success.vue' /* webpackChunkName: "pages/profile/cancel/success" */))
const _62f9e5c9 = () => interopDefault(import('../pages/profile/providers/free.vue' /* webpackChunkName: "pages/profile/providers/free" */))
const _8116b16a = () => interopDefault(import('../pages/profile/providers/orange.vue' /* webpackChunkName: "pages/profile/providers/orange" */))
const _a1636e28 = () => interopDefault(import('../pages/profile/providers/orange-tn.vue' /* webpackChunkName: "pages/profile/providers/orange-tn" */))
const _f8ed0546 = () => interopDefault(import('../pages/profile/reset-password/success.vue' /* webpackChunkName: "pages/profile/reset-password/success" */))
const _15573310 = () => interopDefault(import('../pages/register/campaign/activate.vue' /* webpackChunkName: "pages/register/campaign/activate" */))
const _c600a49a = () => interopDefault(import('../pages/register/campaign/validate.vue' /* webpackChunkName: "pages/register/campaign/validate" */))
const _9e0a9e4e = () => interopDefault(import('../pages/register/dimoco/challenge.vue' /* webpackChunkName: "pages/register/dimoco/challenge" */))
const _d41dd440 = () => interopDefault(import('../pages/register/giftcard/activate.vue' /* webpackChunkName: "pages/register/giftcard/activate" */))
const _c3c4faca = () => interopDefault(import('../pages/register/giftcard/info.vue' /* webpackChunkName: "pages/register/giftcard/info" */))
const _47dc2aa6 = () => interopDefault(import('../pages/register/giftcard/success.vue' /* webpackChunkName: "pages/register/giftcard/success" */))
const _8856169a = () => interopDefault(import('../pages/register/partner/thank-you.vue' /* webpackChunkName: "pages/register/partner/thank-you" */))
const _7f4e4b3e = () => interopDefault(import('../pages/category/books/_slug.vue' /* webpackChunkName: "pages/category/books/_slug" */))
const _5fd66d9a = () => interopDefault(import('../pages/category/magazines/_slug.vue' /* webpackChunkName: "pages/category/magazines/_slug" */))
const _7be2427a = () => interopDefault(import('../pages/collab/contact/_slug.vue' /* webpackChunkName: "pages/collab/contact/_slug" */))
const _882a9cc2 = () => interopDefault(import('../pages/migration/book/_id.vue' /* webpackChunkName: "pages/migration/book/_id" */))
const _35293684 = () => interopDefault(import('../pages/migration/category/_slug.vue' /* webpackChunkName: "pages/migration/category/_slug" */))
const _0593ca3d = () => interopDefault(import('../pages/register/campaign/_slug.vue' /* webpackChunkName: "pages/register/campaign/_slug" */))
const _fef65840 = () => interopDefault(import('../pages/register/partner/_channel/index.vue' /* webpackChunkName: "pages/register/partner/_channel/index" */))
const _448e1d5c = () => interopDefault(import('../pages/register/partner/_channel/link.vue' /* webpackChunkName: "pages/register/partner/_channel/link" */))
const _28d999f0 = () => interopDefault(import('../pages/author/_slug.vue' /* webpackChunkName: "pages/author/_slug" */))
const _5ecbfb64 = () => interopDefault(import('../pages/book/_slug.vue' /* webpackChunkName: "pages/book/_slug" */))
const _2a1b1cfa = () => interopDefault(import('../pages/content/_slug.vue' /* webpackChunkName: "pages/content/_slug" */))
const _eeac2d24 = () => interopDefault(import('../pages/narrator/_slug.vue' /* webpackChunkName: "pages/narrator/_slug" */))
const _4bbf8048 = () => interopDefault(import('../pages/series/_slug.vue' /* webpackChunkName: "pages/series/_slug" */))
const _442c137c = () => interopDefault(import('../pages/collab/_type/_slug.vue' /* webpackChunkName: "pages/collab/_type/_slug" */))
const _2c447510 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _1c3400dc = () => interopDefault(import('../pages/_iso/partner-collaboration/_slug.vue' /* webpackChunkName: "pages/_iso/partner-collaboration/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link--active',
  linkExactActiveClass: 'link--exact',
  scrollBehavior,

  routes: [{
    path: "/at",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___at"
  }, {
    path: "/at-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___at-en"
  }, {
    path: "/be-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___be-en"
  }, {
    path: "/be-fr",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___be-fr"
  }, {
    path: "/ch",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___ch"
  }, {
    path: "/ch-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___ch-en"
  }, {
    path: "/de",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/de-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___de-en"
  }, {
    path: "/dk",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___dk"
  }, {
    path: "/dk-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___dk-en"
  }, {
    path: "/es",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/es-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___es-en"
  }, {
    path: "/fi",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fi"
  }, {
    path: "/fi-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fi-en"
  }, {
    path: "/fr",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/fr-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr-en"
  }, {
    path: "/nl",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/nl-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl-en"
  }, {
    path: "/no",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___no"
  }, {
    path: "/no-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___no-en"
  }, {
    path: "/se",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___se"
  }, {
    path: "/se-en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___se-en"
  }, {
    path: "/at-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___at-en"
  }, {
    path: "/at-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___at-en"
  }, {
    path: "/at-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___at-en"
  }, {
    path: "/at-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___at-en"
  }, {
    path: "/at-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___at-en"
  }, {
    path: "/at-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___at-en"
  }, {
    path: "/at-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___at-en"
  }, {
    path: "/at-en/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___at-en"
  }, {
    path: "/at-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___at-en"
  }, {
    path: "/at-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___at-en"
  }, {
    path: "/at-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___at-en"
  }, {
    path: "/at-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___at-en"
  }, {
    path: "/at-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___at-en"
  }, {
    path: "/at-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___at-en"
  }, {
    path: "/at/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___at"
  }, {
    path: "/at/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___at"
  }, {
    path: "/at/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___at"
  }, {
    path: "/at/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___at"
  }, {
    path: "/at/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___at"
  }, {
    path: "/at/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___at"
  }, {
    path: "/at/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___at"
  }, {
    path: "/at/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___at"
  }, {
    path: "/at/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___at"
  }, {
    path: "/at/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___at"
  }, {
    path: "/at/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___at"
  }, {
    path: "/at/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___at"
  }, {
    path: "/at/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___at"
  }, {
    path: "/at/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___at"
  }, {
    path: "/be-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___be-en"
  }, {
    path: "/be-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___be-en"
  }, {
    path: "/be-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___be-en"
  }, {
    path: "/be-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___be-en"
  }, {
    path: "/be-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___be-en"
  }, {
    path: "/be-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___be-en"
  }, {
    path: "/be-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___be-en"
  }, {
    path: "/be-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___be-en"
  }, {
    path: "/be-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___be-en"
  }, {
    path: "/be-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___be-en"
  }, {
    path: "/be-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___be-en"
  }, {
    path: "/be-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___be-en"
  }, {
    path: "/be-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___be-en"
  }, {
    path: "/be-fr/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___be-fr"
  }, {
    path: "/be-fr/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___be-fr"
  }, {
    path: "/be-fr/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___be-fr"
  }, {
    path: "/be-fr/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___be-fr"
  }, {
    path: "/be-fr/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___be-fr"
  }, {
    path: "/be-fr/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___be-fr"
  }, {
    path: "/be-fr/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___be-fr"
  }, {
    path: "/be-fr/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___be-fr"
  }, {
    path: "/be-fr/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___be-fr"
  }, {
    path: "/be-fr/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___be-fr"
  }, {
    path: "/be-fr/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___be-fr"
  }, {
    path: "/be-fr/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___be-fr"
  }, {
    path: "/be-fr/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___be-fr"
  }, {
    path: "/ch-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___ch-en"
  }, {
    path: "/ch-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___ch-en"
  }, {
    path: "/ch-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___ch-en"
  }, {
    path: "/ch-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___ch-en"
  }, {
    path: "/ch-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___ch-en"
  }, {
    path: "/ch-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___ch-en"
  }, {
    path: "/ch-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___ch-en"
  }, {
    path: "/ch-en/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___ch-en"
  }, {
    path: "/ch-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___ch-en"
  }, {
    path: "/ch-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ch-en"
  }, {
    path: "/ch-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ch-en"
  }, {
    path: "/ch-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___ch-en"
  }, {
    path: "/ch-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___ch-en"
  }, {
    path: "/ch-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___ch-en"
  }, {
    path: "/ch/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___ch"
  }, {
    path: "/ch/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___ch"
  }, {
    path: "/ch/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___ch"
  }, {
    path: "/ch/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___ch"
  }, {
    path: "/ch/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___ch"
  }, {
    path: "/ch/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___ch"
  }, {
    path: "/ch/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___ch"
  }, {
    path: "/ch/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___ch"
  }, {
    path: "/ch/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___ch"
  }, {
    path: "/ch/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ch"
  }, {
    path: "/ch/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ch"
  }, {
    path: "/ch/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___ch"
  }, {
    path: "/ch/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___ch"
  }, {
    path: "/ch/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___ch"
  }, {
    path: "/de-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___de-en"
  }, {
    path: "/de-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___de-en"
  }, {
    path: "/de-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___de-en"
  }, {
    path: "/de-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___de-en"
  }, {
    path: "/de-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___de-en"
  }, {
    path: "/de-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___de-en"
  }, {
    path: "/de-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___de-en"
  }, {
    path: "/de-en/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___de-en"
  }, {
    path: "/de-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___de-en"
  }, {
    path: "/de-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___de-en"
  }, {
    path: "/de-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___de-en"
  }, {
    path: "/de-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___de-en"
  }, {
    path: "/de-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___de-en"
  }, {
    path: "/de-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___de-en"
  }, {
    path: "/de/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___de"
  }, {
    path: "/de/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___de"
  }, {
    path: "/de/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___de"
  }, {
    path: "/de/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___de"
  }, {
    path: "/de/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___de"
  }, {
    path: "/de/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___de"
  }, {
    path: "/de/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___de"
  }, {
    path: "/de/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___de"
  }, {
    path: "/de/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___de"
  }, {
    path: "/de/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___de"
  }, {
    path: "/de/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___de"
  }, {
    path: "/de/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___de"
  }, {
    path: "/de/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___de"
  }, {
    path: "/de/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___de"
  }, {
    path: "/dk-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___dk-en"
  }, {
    path: "/dk-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___dk-en"
  }, {
    path: "/dk-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___dk-en"
  }, {
    path: "/dk-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___dk-en"
  }, {
    path: "/dk-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___dk-en"
  }, {
    path: "/dk-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___dk-en"
  }, {
    path: "/dk-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___dk-en"
  }, {
    path: "/dk-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___dk-en"
  }, {
    path: "/dk-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___dk-en"
  }, {
    path: "/dk-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___dk-en"
  }, {
    path: "/dk-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___dk-en"
  }, {
    path: "/dk-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___dk-en"
  }, {
    path: "/dk-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___dk-en"
  }, {
    path: "/dk/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___dk"
  }, {
    path: "/dk/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___dk"
  }, {
    path: "/dk/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___dk"
  }, {
    path: "/dk/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___dk"
  }, {
    path: "/dk/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___dk"
  }, {
    path: "/dk/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___dk"
  }, {
    path: "/dk/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___dk"
  }, {
    path: "/dk/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___dk"
  }, {
    path: "/dk/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___dk"
  }, {
    path: "/dk/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___dk"
  }, {
    path: "/dk/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___dk"
  }, {
    path: "/dk/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___dk"
  }, {
    path: "/dk/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___dk"
  }, {
    path: "/es-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___es-en"
  }, {
    path: "/es-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___es-en"
  }, {
    path: "/es-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___es-en"
  }, {
    path: "/es-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___es-en"
  }, {
    path: "/es-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___es-en"
  }, {
    path: "/es-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___es-en"
  }, {
    path: "/es-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___es-en"
  }, {
    path: "/es-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___es-en"
  }, {
    path: "/es-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___es-en"
  }, {
    path: "/es-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___es-en"
  }, {
    path: "/es-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___es-en"
  }, {
    path: "/es-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___es-en"
  }, {
    path: "/es-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___es-en"
  }, {
    path: "/es/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___es"
  }, {
    path: "/es/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___es"
  }, {
    path: "/es/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___es"
  }, {
    path: "/es/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___es"
  }, {
    path: "/es/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___es"
  }, {
    path: "/es/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___es"
  }, {
    path: "/es/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___es"
  }, {
    path: "/es/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___es"
  }, {
    path: "/es/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___es"
  }, {
    path: "/es/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___es"
  }, {
    path: "/es/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___es"
  }, {
    path: "/es/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___es"
  }, {
    path: "/es/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___es"
  }, {
    path: "/fi-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fi-en"
  }, {
    path: "/fi-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___fi-en"
  }, {
    path: "/fi-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___fi-en"
  }, {
    path: "/fi-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___fi-en"
  }, {
    path: "/fi-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___fi-en"
  }, {
    path: "/fi-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___fi-en"
  }, {
    path: "/fi-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___fi-en"
  }, {
    path: "/fi-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___fi-en"
  }, {
    path: "/fi-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fi-en"
  }, {
    path: "/fi-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fi-en"
  }, {
    path: "/fi-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___fi-en"
  }, {
    path: "/fi-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fi-en"
  }, {
    path: "/fi-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___fi-en"
  }, {
    path: "/fi/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fi"
  }, {
    path: "/fi/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___fi"
  }, {
    path: "/fi/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___fi"
  }, {
    path: "/fi/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___fi"
  }, {
    path: "/fi/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___fi"
  }, {
    path: "/fi/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___fi"
  }, {
    path: "/fi/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___fi"
  }, {
    path: "/fi/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___fi"
  }, {
    path: "/fi/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fi"
  }, {
    path: "/fi/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fi"
  }, {
    path: "/fi/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___fi"
  }, {
    path: "/fi/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fi"
  }, {
    path: "/fi/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___fi"
  }, {
    path: "/fr-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fr-en"
  }, {
    path: "/fr-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___fr-en"
  }, {
    path: "/fr-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___fr-en"
  }, {
    path: "/fr-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___fr-en"
  }, {
    path: "/fr-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___fr-en"
  }, {
    path: "/fr-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___fr-en"
  }, {
    path: "/fr-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___fr-en"
  }, {
    path: "/fr-en/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___fr-en"
  }, {
    path: "/fr-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___fr-en"
  }, {
    path: "/fr-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fr-en"
  }, {
    path: "/fr-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fr-en"
  }, {
    path: "/fr-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___fr-en"
  }, {
    path: "/fr-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr-en"
  }, {
    path: "/fr-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___fr-en"
  }, {
    path: "/fr/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fr"
  }, {
    path: "/fr/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___fr"
  }, {
    path: "/fr/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___fr"
  }, {
    path: "/fr/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___fr"
  }, {
    path: "/fr/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___fr"
  }, {
    path: "/fr/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___fr"
  }, {
    path: "/fr/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___fr"
  }, {
    path: "/fr/imprint",
    component: _3466728f,
    pathToRegexpOptions: {"strict":true},
    name: "imprint___fr"
  }, {
    path: "/fr/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fr"
  }, {
    path: "/fr/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fr"
  }, {
    path: "/fr/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___fr"
  }, {
    path: "/fr/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr"
  }, {
    path: "/fr/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___fr"
  }, {
    path: "/nl-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___nl-en"
  }, {
    path: "/nl-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___nl-en"
  }, {
    path: "/nl-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___nl-en"
  }, {
    path: "/nl-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___nl-en"
  }, {
    path: "/nl-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___nl-en"
  }, {
    path: "/nl-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___nl-en"
  }, {
    path: "/nl-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___nl-en"
  }, {
    path: "/nl-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___nl-en"
  }, {
    path: "/nl-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___nl-en"
  }, {
    path: "/nl-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___nl-en"
  }, {
    path: "/nl-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___nl-en"
  }, {
    path: "/nl-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___nl-en"
  }, {
    path: "/nl-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___nl-en"
  }, {
    path: "/nl/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___nl"
  }, {
    path: "/nl/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___nl"
  }, {
    path: "/nl/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___nl"
  }, {
    path: "/nl/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___nl"
  }, {
    path: "/nl/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___nl"
  }, {
    path: "/nl/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___nl"
  }, {
    path: "/nl/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___nl"
  }, {
    path: "/nl/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___nl"
  }, {
    path: "/nl/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___nl"
  }, {
    path: "/nl/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___nl"
  }, {
    path: "/nl/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___nl"
  }, {
    path: "/nl/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___nl"
  }, {
    path: "/nl/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___nl"
  }, {
    path: "/no-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___no-en"
  }, {
    path: "/no-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___no-en"
  }, {
    path: "/no-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___no-en"
  }, {
    path: "/no-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___no-en"
  }, {
    path: "/no-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___no-en"
  }, {
    path: "/no-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___no-en"
  }, {
    path: "/no-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___no-en"
  }, {
    path: "/no-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___no-en"
  }, {
    path: "/no-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___no-en"
  }, {
    path: "/no-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___no-en"
  }, {
    path: "/no-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___no-en"
  }, {
    path: "/no-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___no-en"
  }, {
    path: "/no-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___no-en"
  }, {
    path: "/no/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___no"
  }, {
    path: "/no/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___no"
  }, {
    path: "/no/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___no"
  }, {
    path: "/no/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___no"
  }, {
    path: "/no/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___no"
  }, {
    path: "/no/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___no"
  }, {
    path: "/no/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___no"
  }, {
    path: "/no/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___no"
  }, {
    path: "/no/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___no"
  }, {
    path: "/no/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___no"
  }, {
    path: "/no/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___no"
  }, {
    path: "/no/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___no"
  }, {
    path: "/no/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___no"
  }, {
    path: "/se-en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___se-en"
  }, {
    path: "/se-en/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___se-en"
  }, {
    path: "/se-en/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___se-en"
  }, {
    path: "/se-en/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___se-en"
  }, {
    path: "/se-en/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___se-en"
  }, {
    path: "/se-en/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___se-en"
  }, {
    path: "/se-en/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___se-en"
  }, {
    path: "/se-en/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___se-en"
  }, {
    path: "/se-en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___se-en"
  }, {
    path: "/se-en/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___se-en"
  }, {
    path: "/se-en/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___se-en"
  }, {
    path: "/se-en/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___se-en"
  }, {
    path: "/se-en/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___se-en"
  }, {
    path: "/se/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___se"
  }, {
    path: "/se/accessibility",
    component: _afe17a58,
    pathToRegexpOptions: {"strict":true},
    name: "accessibility___se"
  }, {
    path: "/se/audiobooks",
    component: _7559d404,
    pathToRegexpOptions: {"strict":true},
    name: "audiobooks___se"
  }, {
    path: "/se/category",
    component: _1714966b,
    pathToRegexpOptions: {"strict":true},
    name: "category___se"
  }, {
    path: "/se/download-apps",
    component: _175c24c6,
    pathToRegexpOptions: {"strict":true},
    name: "download-apps___se"
  }, {
    path: "/se/e-books",
    component: _9962ed70,
    pathToRegexpOptions: {"strict":true},
    name: "e-books___se"
  }, {
    path: "/se/error",
    component: _71c1348e,
    pathToRegexpOptions: {"strict":true},
    name: "error___se"
  }, {
    path: "/se/magazines",
    component: _e29c0136,
    pathToRegexpOptions: {"strict":true},
    name: "magazines___se"
  }, {
    path: "/se/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___se"
  }, {
    path: "/se/profile",
    component: _4bb7511c,
    pathToRegexpOptions: {"strict":true},
    name: "profile___se"
  }, {
    path: "/se/register",
    component: _cc080460,
    pathToRegexpOptions: {"strict":true},
    name: "register___se"
  }, {
    path: "/se/search",
    component: _3d268e12,
    pathToRegexpOptions: {"strict":true},
    name: "search___se"
  }, {
    path: "/se/user-terms",
    component: _52e40422,
    pathToRegexpOptions: {"strict":true},
    name: "user-terms___se"
  }, {
    path: "/at-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___at-en"
  }, {
    path: "/at-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___at-en"
  }, {
    path: "/at-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___at-en"
  }, {
    path: "/at-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___at-en"
  }, {
    path: "/at-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___at-en"
  }, {
    path: "/at-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___at-en"
  }, {
    path: "/at-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___at-en"
  }, {
    path: "/at-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___at-en"
  }, {
    path: "/at-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___at-en"
  }, {
    path: "/at-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___at-en"
  }, {
    path: "/at-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___at-en"
  }, {
    path: "/at-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___at-en"
  }, {
    path: "/at-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___at-en"
  }, {
    path: "/at-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___at-en"
  }, {
    path: "/at-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___at-en"
  }, {
    path: "/at-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___at-en"
  }, {
    path: "/at-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___at-en"
  }, {
    path: "/at-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___at-en"
  }, {
    path: "/at-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___at-en"
  }, {
    path: "/at-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___at-en"
  }, {
    path: "/at-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___at-en"
  }, {
    path: "/at-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___at-en"
  }, {
    path: "/at-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___at-en"
  }, {
    path: "/at-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___at-en"
  }, {
    path: "/at-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___at-en"
  }, {
    path: "/at-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___at-en"
  }, {
    path: "/at-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___at-en"
  }, {
    path: "/at-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___at-en"
  }, {
    path: "/at-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___at-en"
  }, {
    path: "/at-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___at-en"
  }, {
    path: "/at-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___at-en"
  }, {
    path: "/at/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___at"
  }, {
    path: "/at/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___at"
  }, {
    path: "/at/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___at"
  }, {
    path: "/at/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___at"
  }, {
    path: "/at/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___at"
  }, {
    path: "/at/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___at"
  }, {
    path: "/at/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___at"
  }, {
    path: "/at/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___at"
  }, {
    path: "/at/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___at"
  }, {
    path: "/at/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___at"
  }, {
    path: "/at/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___at"
  }, {
    path: "/at/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___at"
  }, {
    path: "/at/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___at"
  }, {
    path: "/at/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___at"
  }, {
    path: "/at/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___at"
  }, {
    path: "/at/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___at"
  }, {
    path: "/at/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___at"
  }, {
    path: "/at/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___at"
  }, {
    path: "/at/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___at"
  }, {
    path: "/at/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___at"
  }, {
    path: "/at/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___at"
  }, {
    path: "/at/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___at"
  }, {
    path: "/at/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___at"
  }, {
    path: "/at/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___at"
  }, {
    path: "/at/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___at"
  }, {
    path: "/at/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___at"
  }, {
    path: "/at/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___at"
  }, {
    path: "/at/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___at"
  }, {
    path: "/at/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___at"
  }, {
    path: "/at/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___at"
  }, {
    path: "/at/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___at"
  }, {
    path: "/be-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___be-en"
  }, {
    path: "/be-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___be-en"
  }, {
    path: "/be-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___be-en"
  }, {
    path: "/be-en/in-app/launch",
    component: _3ea7650f,
    pathToRegexpOptions: {"strict":true},
    name: "in-app-launch___be-en"
  }, {
    path: "/be-en/launch/nextory-app",
    component: _653dcff6,
    pathToRegexpOptions: {"strict":true},
    name: "launch-nextory-app___be-en"
  }, {
    path: "/be-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___be-en"
  }, {
    path: "/be-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___be-en"
  }, {
    path: "/be-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___be-en"
  }, {
    path: "/be-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___be-en"
  }, {
    path: "/be-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___be-en"
  }, {
    path: "/be-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___be-en"
  }, {
    path: "/be-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___be-en"
  }, {
    path: "/be-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___be-en"
  }, {
    path: "/be-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___be-en"
  }, {
    path: "/be-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___be-en"
  }, {
    path: "/be-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___be-en"
  }, {
    path: "/be-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___be-en"
  }, {
    path: "/be-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___be-en"
  }, {
    path: "/be-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___be-en"
  }, {
    path: "/be-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___be-en"
  }, {
    path: "/be-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___be-en"
  }, {
    path: "/be-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___be-en"
  }, {
    path: "/be-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___be-en"
  }, {
    path: "/be-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___be-en"
  }, {
    path: "/be-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___be-en"
  }, {
    path: "/be-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___be-en"
  }, {
    path: "/be-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___be-en"
  }, {
    path: "/be-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___be-en"
  }, {
    path: "/be-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___be-en"
  }, {
    path: "/be-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___be-en"
  }, {
    path: "/be-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___be-en"
  }, {
    path: "/be-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___be-en"
  }, {
    path: "/be-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___be-en"
  }, {
    path: "/be-fr/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___be-fr"
  }, {
    path: "/be-fr/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___be-fr"
  }, {
    path: "/be-fr/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___be-fr"
  }, {
    path: "/be-fr/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___be-fr"
  }, {
    path: "/be-fr/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___be-fr"
  }, {
    path: "/be-fr/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___be-fr"
  }, {
    path: "/be-fr/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___be-fr"
  }, {
    path: "/be-fr/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___be-fr"
  }, {
    path: "/be-fr/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___be-fr"
  }, {
    path: "/be-fr/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___be-fr"
  }, {
    path: "/be-fr/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___be-fr"
  }, {
    path: "/be-fr/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___be-fr"
  }, {
    path: "/be-fr/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___be-fr"
  }, {
    path: "/be-fr/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___be-fr"
  }, {
    path: "/be-fr/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___be-fr"
  }, {
    path: "/be-fr/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___be-fr"
  }, {
    path: "/be-fr/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___be-fr"
  }, {
    path: "/be-fr/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___be-fr"
  }, {
    path: "/be-fr/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___be-fr"
  }, {
    path: "/be-fr/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___be-fr"
  }, {
    path: "/be-fr/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___be-fr"
  }, {
    path: "/be-fr/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___be-fr"
  }, {
    path: "/be-fr/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___be-fr"
  }, {
    path: "/be-fr/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___be-fr"
  }, {
    path: "/be-fr/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___be-fr"
  }, {
    path: "/be-fr/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___be-fr"
  }, {
    path: "/be-fr/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___be-fr"
  }, {
    path: "/be-fr/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___be-fr"
  }, {
    path: "/be-fr/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___be-fr"
  }, {
    path: "/be-fr/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___be-fr"
  }, {
    path: "/be-fr/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___be-fr"
  }, {
    path: "/ch-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___ch-en"
  }, {
    path: "/ch-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___ch-en"
  }, {
    path: "/ch-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___ch-en"
  }, {
    path: "/ch-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___ch-en"
  }, {
    path: "/ch-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___ch-en"
  }, {
    path: "/ch-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___ch-en"
  }, {
    path: "/ch-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___ch-en"
  }, {
    path: "/ch-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___ch-en"
  }, {
    path: "/ch-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___ch-en"
  }, {
    path: "/ch-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___ch-en"
  }, {
    path: "/ch-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___ch-en"
  }, {
    path: "/ch-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___ch-en"
  }, {
    path: "/ch-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___ch-en"
  }, {
    path: "/ch-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___ch-en"
  }, {
    path: "/ch-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___ch-en"
  }, {
    path: "/ch-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___ch-en"
  }, {
    path: "/ch-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___ch-en"
  }, {
    path: "/ch-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___ch-en"
  }, {
    path: "/ch-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___ch-en"
  }, {
    path: "/ch-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___ch-en"
  }, {
    path: "/ch-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___ch-en"
  }, {
    path: "/ch-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___ch-en"
  }, {
    path: "/ch-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___ch-en"
  }, {
    path: "/ch-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___ch-en"
  }, {
    path: "/ch-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___ch-en"
  }, {
    path: "/ch-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___ch-en"
  }, {
    path: "/ch-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___ch-en"
  }, {
    path: "/ch-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___ch-en"
  }, {
    path: "/ch-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___ch-en"
  }, {
    path: "/ch-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___ch-en"
  }, {
    path: "/ch-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___ch-en"
  }, {
    path: "/ch/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___ch"
  }, {
    path: "/ch/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___ch"
  }, {
    path: "/ch/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___ch"
  }, {
    path: "/ch/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___ch"
  }, {
    path: "/ch/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___ch"
  }, {
    path: "/ch/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___ch"
  }, {
    path: "/ch/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___ch"
  }, {
    path: "/ch/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___ch"
  }, {
    path: "/ch/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___ch"
  }, {
    path: "/ch/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___ch"
  }, {
    path: "/ch/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___ch"
  }, {
    path: "/ch/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___ch"
  }, {
    path: "/ch/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___ch"
  }, {
    path: "/ch/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___ch"
  }, {
    path: "/ch/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___ch"
  }, {
    path: "/ch/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___ch"
  }, {
    path: "/ch/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___ch"
  }, {
    path: "/ch/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___ch"
  }, {
    path: "/ch/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___ch"
  }, {
    path: "/ch/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___ch"
  }, {
    path: "/ch/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___ch"
  }, {
    path: "/ch/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___ch"
  }, {
    path: "/ch/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___ch"
  }, {
    path: "/ch/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___ch"
  }, {
    path: "/ch/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___ch"
  }, {
    path: "/ch/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___ch"
  }, {
    path: "/ch/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___ch"
  }, {
    path: "/ch/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___ch"
  }, {
    path: "/ch/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___ch"
  }, {
    path: "/ch/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___ch"
  }, {
    path: "/ch/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___ch"
  }, {
    path: "/de-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___de-en"
  }, {
    path: "/de-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___de-en"
  }, {
    path: "/de-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___de-en"
  }, {
    path: "/de-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___de-en"
  }, {
    path: "/de-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___de-en"
  }, {
    path: "/de-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___de-en"
  }, {
    path: "/de-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___de-en"
  }, {
    path: "/de-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___de-en"
  }, {
    path: "/de-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___de-en"
  }, {
    path: "/de-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___de-en"
  }, {
    path: "/de-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___de-en"
  }, {
    path: "/de-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___de-en"
  }, {
    path: "/de-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___de-en"
  }, {
    path: "/de-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___de-en"
  }, {
    path: "/de-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___de-en"
  }, {
    path: "/de-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___de-en"
  }, {
    path: "/de-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___de-en"
  }, {
    path: "/de-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___de-en"
  }, {
    path: "/de-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___de-en"
  }, {
    path: "/de-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___de-en"
  }, {
    path: "/de-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___de-en"
  }, {
    path: "/de-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___de-en"
  }, {
    path: "/de-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___de-en"
  }, {
    path: "/de-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___de-en"
  }, {
    path: "/de-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___de-en"
  }, {
    path: "/de-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___de-en"
  }, {
    path: "/de-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___de-en"
  }, {
    path: "/de-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___de-en"
  }, {
    path: "/de-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___de-en"
  }, {
    path: "/de-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___de-en"
  }, {
    path: "/de-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___de-en"
  }, {
    path: "/de/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___de"
  }, {
    path: "/de/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___de"
  }, {
    path: "/de/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___de"
  }, {
    path: "/de/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___de"
  }, {
    path: "/de/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___de"
  }, {
    path: "/de/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___de"
  }, {
    path: "/de/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___de"
  }, {
    path: "/de/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___de"
  }, {
    path: "/de/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___de"
  }, {
    path: "/de/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___de"
  }, {
    path: "/de/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___de"
  }, {
    path: "/de/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___de"
  }, {
    path: "/de/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___de"
  }, {
    path: "/de/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___de"
  }, {
    path: "/de/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___de"
  }, {
    path: "/de/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___de"
  }, {
    path: "/de/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___de"
  }, {
    path: "/de/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___de"
  }, {
    path: "/de/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___de"
  }, {
    path: "/de/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___de"
  }, {
    path: "/de/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___de"
  }, {
    path: "/de/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___de"
  }, {
    path: "/de/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___de"
  }, {
    path: "/de/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___de"
  }, {
    path: "/de/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___de"
  }, {
    path: "/de/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___de"
  }, {
    path: "/de/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___de"
  }, {
    path: "/de/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___de"
  }, {
    path: "/de/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___de"
  }, {
    path: "/de/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___de"
  }, {
    path: "/de/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___de"
  }, {
    path: "/dk-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___dk-en"
  }, {
    path: "/dk-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___dk-en"
  }, {
    path: "/dk-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___dk-en"
  }, {
    path: "/dk-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___dk-en"
  }, {
    path: "/dk-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___dk-en"
  }, {
    path: "/dk-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___dk-en"
  }, {
    path: "/dk-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___dk-en"
  }, {
    path: "/dk-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___dk-en"
  }, {
    path: "/dk-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___dk-en"
  }, {
    path: "/dk-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___dk-en"
  }, {
    path: "/dk-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___dk-en"
  }, {
    path: "/dk-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___dk-en"
  }, {
    path: "/dk-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___dk-en"
  }, {
    path: "/dk-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___dk-en"
  }, {
    path: "/dk-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___dk-en"
  }, {
    path: "/dk-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___dk-en"
  }, {
    path: "/dk-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___dk-en"
  }, {
    path: "/dk-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___dk-en"
  }, {
    path: "/dk-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___dk-en"
  }, {
    path: "/dk-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___dk-en"
  }, {
    path: "/dk-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___dk-en"
  }, {
    path: "/dk-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___dk-en"
  }, {
    path: "/dk-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___dk-en"
  }, {
    path: "/dk-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___dk-en"
  }, {
    path: "/dk-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___dk-en"
  }, {
    path: "/dk-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___dk-en"
  }, {
    path: "/dk-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___dk-en"
  }, {
    path: "/dk-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___dk-en"
  }, {
    path: "/dk-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___dk-en"
  }, {
    path: "/dk-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___dk-en"
  }, {
    path: "/dk-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___dk-en"
  }, {
    path: "/dk/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___dk"
  }, {
    path: "/dk/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___dk"
  }, {
    path: "/dk/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___dk"
  }, {
    path: "/dk/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___dk"
  }, {
    path: "/dk/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___dk"
  }, {
    path: "/dk/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___dk"
  }, {
    path: "/dk/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___dk"
  }, {
    path: "/dk/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___dk"
  }, {
    path: "/dk/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___dk"
  }, {
    path: "/dk/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___dk"
  }, {
    path: "/dk/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___dk"
  }, {
    path: "/dk/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___dk"
  }, {
    path: "/dk/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___dk"
  }, {
    path: "/dk/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___dk"
  }, {
    path: "/dk/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___dk"
  }, {
    path: "/dk/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___dk"
  }, {
    path: "/dk/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___dk"
  }, {
    path: "/dk/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___dk"
  }, {
    path: "/dk/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___dk"
  }, {
    path: "/dk/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___dk"
  }, {
    path: "/dk/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___dk"
  }, {
    path: "/dk/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___dk"
  }, {
    path: "/dk/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___dk"
  }, {
    path: "/dk/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___dk"
  }, {
    path: "/dk/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___dk"
  }, {
    path: "/dk/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___dk"
  }, {
    path: "/dk/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___dk"
  }, {
    path: "/dk/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___dk"
  }, {
    path: "/dk/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___dk"
  }, {
    path: "/dk/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___dk"
  }, {
    path: "/dk/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___dk"
  }, {
    path: "/es-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___es-en"
  }, {
    path: "/es-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___es-en"
  }, {
    path: "/es-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___es-en"
  }, {
    path: "/es-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___es-en"
  }, {
    path: "/es-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___es-en"
  }, {
    path: "/es-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___es-en"
  }, {
    path: "/es-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___es-en"
  }, {
    path: "/es-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___es-en"
  }, {
    path: "/es-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___es-en"
  }, {
    path: "/es-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___es-en"
  }, {
    path: "/es-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___es-en"
  }, {
    path: "/es-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___es-en"
  }, {
    path: "/es-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___es-en"
  }, {
    path: "/es-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___es-en"
  }, {
    path: "/es-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___es-en"
  }, {
    path: "/es-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___es-en"
  }, {
    path: "/es-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___es-en"
  }, {
    path: "/es-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___es-en"
  }, {
    path: "/es-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___es-en"
  }, {
    path: "/es-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___es-en"
  }, {
    path: "/es-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___es-en"
  }, {
    path: "/es-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___es-en"
  }, {
    path: "/es-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___es-en"
  }, {
    path: "/es-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___es-en"
  }, {
    path: "/es-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___es-en"
  }, {
    path: "/es-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___es-en"
  }, {
    path: "/es-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___es-en"
  }, {
    path: "/es-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___es-en"
  }, {
    path: "/es-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___es-en"
  }, {
    path: "/es-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___es-en"
  }, {
    path: "/es-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___es-en"
  }, {
    path: "/es/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___es"
  }, {
    path: "/es/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___es"
  }, {
    path: "/es/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___es"
  }, {
    path: "/es/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___es"
  }, {
    path: "/es/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___es"
  }, {
    path: "/es/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___es"
  }, {
    path: "/es/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___es"
  }, {
    path: "/es/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___es"
  }, {
    path: "/es/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___es"
  }, {
    path: "/es/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___es"
  }, {
    path: "/es/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___es"
  }, {
    path: "/es/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___es"
  }, {
    path: "/es/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___es"
  }, {
    path: "/es/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___es"
  }, {
    path: "/es/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___es"
  }, {
    path: "/es/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___es"
  }, {
    path: "/es/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___es"
  }, {
    path: "/es/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___es"
  }, {
    path: "/es/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___es"
  }, {
    path: "/es/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___es"
  }, {
    path: "/es/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___es"
  }, {
    path: "/es/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___es"
  }, {
    path: "/es/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___es"
  }, {
    path: "/es/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___es"
  }, {
    path: "/es/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___es"
  }, {
    path: "/es/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___es"
  }, {
    path: "/es/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___es"
  }, {
    path: "/es/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___es"
  }, {
    path: "/es/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___es"
  }, {
    path: "/es/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___es"
  }, {
    path: "/es/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___es"
  }, {
    path: "/fi-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___fi-en"
  }, {
    path: "/fi-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___fi-en"
  }, {
    path: "/fi-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___fi-en"
  }, {
    path: "/fi-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___fi-en"
  }, {
    path: "/fi-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___fi-en"
  }, {
    path: "/fi-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___fi-en"
  }, {
    path: "/fi-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___fi-en"
  }, {
    path: "/fi-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___fi-en"
  }, {
    path: "/fi-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___fi-en"
  }, {
    path: "/fi-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___fi-en"
  }, {
    path: "/fi-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___fi-en"
  }, {
    path: "/fi-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___fi-en"
  }, {
    path: "/fi-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___fi-en"
  }, {
    path: "/fi-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___fi-en"
  }, {
    path: "/fi-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___fi-en"
  }, {
    path: "/fi-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___fi-en"
  }, {
    path: "/fi-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___fi-en"
  }, {
    path: "/fi-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___fi-en"
  }, {
    path: "/fi-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___fi-en"
  }, {
    path: "/fi-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___fi-en"
  }, {
    path: "/fi-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___fi-en"
  }, {
    path: "/fi-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___fi-en"
  }, {
    path: "/fi-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___fi-en"
  }, {
    path: "/fi-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___fi-en"
  }, {
    path: "/fi-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___fi-en"
  }, {
    path: "/fi-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___fi-en"
  }, {
    path: "/fi-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___fi-en"
  }, {
    path: "/fi-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___fi-en"
  }, {
    path: "/fi-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___fi-en"
  }, {
    path: "/fi-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___fi-en"
  }, {
    path: "/fi-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___fi-en"
  }, {
    path: "/fi/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___fi"
  }, {
    path: "/fi/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___fi"
  }, {
    path: "/fi/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___fi"
  }, {
    path: "/fi/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___fi"
  }, {
    path: "/fi/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___fi"
  }, {
    path: "/fi/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___fi"
  }, {
    path: "/fi/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___fi"
  }, {
    path: "/fi/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___fi"
  }, {
    path: "/fi/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___fi"
  }, {
    path: "/fi/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___fi"
  }, {
    path: "/fi/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___fi"
  }, {
    path: "/fi/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___fi"
  }, {
    path: "/fi/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___fi"
  }, {
    path: "/fi/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___fi"
  }, {
    path: "/fi/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___fi"
  }, {
    path: "/fi/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___fi"
  }, {
    path: "/fi/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___fi"
  }, {
    path: "/fi/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___fi"
  }, {
    path: "/fi/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___fi"
  }, {
    path: "/fi/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___fi"
  }, {
    path: "/fi/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___fi"
  }, {
    path: "/fi/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___fi"
  }, {
    path: "/fi/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___fi"
  }, {
    path: "/fi/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___fi"
  }, {
    path: "/fi/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___fi"
  }, {
    path: "/fi/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___fi"
  }, {
    path: "/fi/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___fi"
  }, {
    path: "/fi/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___fi"
  }, {
    path: "/fi/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___fi"
  }, {
    path: "/fi/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___fi"
  }, {
    path: "/fi/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___fi"
  }, {
    path: "/fr-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___fr-en"
  }, {
    path: "/fr-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___fr-en"
  }, {
    path: "/fr-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___fr-en"
  }, {
    path: "/fr-en/in-app/launch",
    component: _3ea7650f,
    pathToRegexpOptions: {"strict":true},
    name: "in-app-launch___fr-en"
  }, {
    path: "/fr-en/launch/nextory-app",
    component: _653dcff6,
    pathToRegexpOptions: {"strict":true},
    name: "launch-nextory-app___fr-en"
  }, {
    path: "/fr-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___fr-en"
  }, {
    path: "/fr-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___fr-en"
  }, {
    path: "/fr-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___fr-en"
  }, {
    path: "/fr-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___fr-en"
  }, {
    path: "/fr-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___fr-en"
  }, {
    path: "/fr-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___fr-en"
  }, {
    path: "/fr-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___fr-en"
  }, {
    path: "/fr-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___fr-en"
  }, {
    path: "/fr-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___fr-en"
  }, {
    path: "/fr-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___fr-en"
  }, {
    path: "/fr-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___fr-en"
  }, {
    path: "/fr-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___fr-en"
  }, {
    path: "/fr-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___fr-en"
  }, {
    path: "/fr-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___fr-en"
  }, {
    path: "/fr-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___fr-en"
  }, {
    path: "/fr-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___fr-en"
  }, {
    path: "/fr-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___fr-en"
  }, {
    path: "/fr-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___fr-en"
  }, {
    path: "/fr-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___fr-en"
  }, {
    path: "/fr-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___fr-en"
  }, {
    path: "/fr-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___fr-en"
  }, {
    path: "/fr-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___fr-en"
  }, {
    path: "/fr-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___fr-en"
  }, {
    path: "/fr-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___fr-en"
  }, {
    path: "/fr-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___fr-en"
  }, {
    path: "/fr-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___fr-en"
  }, {
    path: "/fr-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___fr-en"
  }, {
    path: "/fr-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___fr-en"
  }, {
    path: "/fr/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___fr"
  }, {
    path: "/fr/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___fr"
  }, {
    path: "/fr/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___fr"
  }, {
    path: "/fr/in-app/launch",
    component: _3ea7650f,
    pathToRegexpOptions: {"strict":true},
    name: "in-app-launch___fr"
  }, {
    path: "/fr/launch/nextory-app",
    component: _653dcff6,
    pathToRegexpOptions: {"strict":true},
    name: "launch-nextory-app___fr"
  }, {
    path: "/fr/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___fr"
  }, {
    path: "/fr/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___fr"
  }, {
    path: "/fr/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___fr"
  }, {
    path: "/fr/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___fr"
  }, {
    path: "/fr/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___fr"
  }, {
    path: "/fr/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___fr"
  }, {
    path: "/fr/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___fr"
  }, {
    path: "/fr/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___fr"
  }, {
    path: "/fr/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___fr"
  }, {
    path: "/fr/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___fr"
  }, {
    path: "/fr/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___fr"
  }, {
    path: "/fr/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___fr"
  }, {
    path: "/fr/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___fr"
  }, {
    path: "/fr/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___fr"
  }, {
    path: "/fr/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___fr"
  }, {
    path: "/fr/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___fr"
  }, {
    path: "/fr/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___fr"
  }, {
    path: "/fr/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___fr"
  }, {
    path: "/fr/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___fr"
  }, {
    path: "/fr/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___fr"
  }, {
    path: "/fr/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___fr"
  }, {
    path: "/fr/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___fr"
  }, {
    path: "/fr/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___fr"
  }, {
    path: "/fr/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___fr"
  }, {
    path: "/fr/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___fr"
  }, {
    path: "/fr/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___fr"
  }, {
    path: "/fr/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___fr"
  }, {
    path: "/fr/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___fr"
  }, {
    path: "/nl-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___nl-en"
  }, {
    path: "/nl-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___nl-en"
  }, {
    path: "/nl-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___nl-en"
  }, {
    path: "/nl-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___nl-en"
  }, {
    path: "/nl-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___nl-en"
  }, {
    path: "/nl-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___nl-en"
  }, {
    path: "/nl-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___nl-en"
  }, {
    path: "/nl-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___nl-en"
  }, {
    path: "/nl-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___nl-en"
  }, {
    path: "/nl-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___nl-en"
  }, {
    path: "/nl-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___nl-en"
  }, {
    path: "/nl-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___nl-en"
  }, {
    path: "/nl-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___nl-en"
  }, {
    path: "/nl-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___nl-en"
  }, {
    path: "/nl-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___nl-en"
  }, {
    path: "/nl-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___nl-en"
  }, {
    path: "/nl-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___nl-en"
  }, {
    path: "/nl-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___nl-en"
  }, {
    path: "/nl-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___nl-en"
  }, {
    path: "/nl-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___nl-en"
  }, {
    path: "/nl-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___nl-en"
  }, {
    path: "/nl-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___nl-en"
  }, {
    path: "/nl-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___nl-en"
  }, {
    path: "/nl-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___nl-en"
  }, {
    path: "/nl-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___nl-en"
  }, {
    path: "/nl-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___nl-en"
  }, {
    path: "/nl-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___nl-en"
  }, {
    path: "/nl-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___nl-en"
  }, {
    path: "/nl-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___nl-en"
  }, {
    path: "/nl-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___nl-en"
  }, {
    path: "/nl-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___nl-en"
  }, {
    path: "/nl/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___nl"
  }, {
    path: "/nl/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___nl"
  }, {
    path: "/nl/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___nl"
  }, {
    path: "/nl/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___nl"
  }, {
    path: "/nl/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___nl"
  }, {
    path: "/nl/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___nl"
  }, {
    path: "/nl/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___nl"
  }, {
    path: "/nl/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___nl"
  }, {
    path: "/nl/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___nl"
  }, {
    path: "/nl/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___nl"
  }, {
    path: "/nl/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___nl"
  }, {
    path: "/nl/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___nl"
  }, {
    path: "/nl/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___nl"
  }, {
    path: "/nl/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___nl"
  }, {
    path: "/nl/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___nl"
  }, {
    path: "/nl/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___nl"
  }, {
    path: "/nl/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___nl"
  }, {
    path: "/nl/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___nl"
  }, {
    path: "/nl/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___nl"
  }, {
    path: "/nl/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___nl"
  }, {
    path: "/nl/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___nl"
  }, {
    path: "/nl/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___nl"
  }, {
    path: "/nl/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___nl"
  }, {
    path: "/nl/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___nl"
  }, {
    path: "/nl/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___nl"
  }, {
    path: "/nl/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___nl"
  }, {
    path: "/nl/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___nl"
  }, {
    path: "/nl/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___nl"
  }, {
    path: "/nl/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___nl"
  }, {
    path: "/nl/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___nl"
  }, {
    path: "/nl/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___nl"
  }, {
    path: "/no-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___no-en"
  }, {
    path: "/no-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___no-en"
  }, {
    path: "/no-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___no-en"
  }, {
    path: "/no-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___no-en"
  }, {
    path: "/no-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___no-en"
  }, {
    path: "/no-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___no-en"
  }, {
    path: "/no-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___no-en"
  }, {
    path: "/no-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___no-en"
  }, {
    path: "/no-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___no-en"
  }, {
    path: "/no-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___no-en"
  }, {
    path: "/no-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___no-en"
  }, {
    path: "/no-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___no-en"
  }, {
    path: "/no-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___no-en"
  }, {
    path: "/no-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___no-en"
  }, {
    path: "/no-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___no-en"
  }, {
    path: "/no-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___no-en"
  }, {
    path: "/no-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___no-en"
  }, {
    path: "/no-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___no-en"
  }, {
    path: "/no-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___no-en"
  }, {
    path: "/no-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___no-en"
  }, {
    path: "/no-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___no-en"
  }, {
    path: "/no-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___no-en"
  }, {
    path: "/no-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___no-en"
  }, {
    path: "/no-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___no-en"
  }, {
    path: "/no-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___no-en"
  }, {
    path: "/no-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___no-en"
  }, {
    path: "/no-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___no-en"
  }, {
    path: "/no-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___no-en"
  }, {
    path: "/no-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___no-en"
  }, {
    path: "/no-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___no-en"
  }, {
    path: "/no-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___no-en"
  }, {
    path: "/no/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___no"
  }, {
    path: "/no/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___no"
  }, {
    path: "/no/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___no"
  }, {
    path: "/no/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___no"
  }, {
    path: "/no/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___no"
  }, {
    path: "/no/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___no"
  }, {
    path: "/no/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___no"
  }, {
    path: "/no/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___no"
  }, {
    path: "/no/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___no"
  }, {
    path: "/no/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___no"
  }, {
    path: "/no/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___no"
  }, {
    path: "/no/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___no"
  }, {
    path: "/no/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___no"
  }, {
    path: "/no/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___no"
  }, {
    path: "/no/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___no"
  }, {
    path: "/no/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___no"
  }, {
    path: "/no/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___no"
  }, {
    path: "/no/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___no"
  }, {
    path: "/no/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___no"
  }, {
    path: "/no/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___no"
  }, {
    path: "/no/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___no"
  }, {
    path: "/no/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___no"
  }, {
    path: "/no/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___no"
  }, {
    path: "/no/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___no"
  }, {
    path: "/no/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___no"
  }, {
    path: "/no/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___no"
  }, {
    path: "/no/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___no"
  }, {
    path: "/no/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___no"
  }, {
    path: "/no/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___no"
  }, {
    path: "/no/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___no"
  }, {
    path: "/no/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___no"
  }, {
    path: "/se-en/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___se-en"
  }, {
    path: "/se-en/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___se-en"
  }, {
    path: "/se-en/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___se-en"
  }, {
    path: "/se-en/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___se-en"
  }, {
    path: "/se-en/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___se-en"
  }, {
    path: "/se-en/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___se-en"
  }, {
    path: "/se-en/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___se-en"
  }, {
    path: "/se-en/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___se-en"
  }, {
    path: "/se-en/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___se-en"
  }, {
    path: "/se-en/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___se-en"
  }, {
    path: "/se-en/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___se-en"
  }, {
    path: "/se-en/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___se-en"
  }, {
    path: "/se-en/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___se-en"
  }, {
    path: "/se-en/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___se-en"
  }, {
    path: "/se-en/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___se-en"
  }, {
    path: "/se-en/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___se-en"
  }, {
    path: "/se-en/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___se-en"
  }, {
    path: "/se-en/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___se-en"
  }, {
    path: "/se-en/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___se-en"
  }, {
    path: "/se-en/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___se-en"
  }, {
    path: "/se-en/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___se-en"
  }, {
    path: "/se-en/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___se-en"
  }, {
    path: "/se-en/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___se-en"
  }, {
    path: "/se-en/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___se-en"
  }, {
    path: "/se-en/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___se-en"
  }, {
    path: "/se-en/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___se-en"
  }, {
    path: "/se-en/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___se-en"
  }, {
    path: "/se-en/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___se-en"
  }, {
    path: "/se-en/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___se-en"
  }, {
    path: "/se-en/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___se-en"
  }, {
    path: "/se-en/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___se-en"
  }, {
    path: "/se/action/login",
    component: _3dbaa13a,
    pathToRegexpOptions: {"strict":true},
    name: "action-login___se"
  }, {
    path: "/se/categories/books",
    component: _ff35193e,
    pathToRegexpOptions: {"strict":true},
    name: "categories-books___se"
  }, {
    path: "/se/categories/magazines",
    component: _025be3d4,
    pathToRegexpOptions: {"strict":true},
    name: "categories-magazines___se"
  }, {
    path: "/se/profile/change-email",
    component: _32250762,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-email___se"
  }, {
    path: "/se/profile/change-password",
    component: _7d7cc1b8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-change-password___se"
  }, {
    path: "/se/profile/login",
    component: _b8680aee,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login___se"
  }, {
    path: "/se/profile/login-partner",
    component: _422cc404,
    pathToRegexpOptions: {"strict":true},
    name: "profile-login-partner___se"
  }, {
    path: "/se/profile/order-history",
    component: _4e762396,
    pathToRegexpOptions: {"strict":true},
    name: "profile-order-history___se"
  }, {
    path: "/se/profile/profile",
    component: _48375a6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-profile___se"
  }, {
    path: "/se/profile/quick-login",
    component: _762958a9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-quick-login___se"
  }, {
    path: "/se/profile/read-and-listen-again",
    component: _9c2d48f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-read-and-listen-again___se"
  }, {
    path: "/se/profile/receipt",
    component: _4a835bd8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-receipt___se"
  }, {
    path: "/se/profile/registration",
    component: _4e9bae6e,
    pathToRegexpOptions: {"strict":true},
    name: "profile-registration___se"
  }, {
    path: "/se/profile/reset-password",
    component: _48288f2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password___se"
  }, {
    path: "/se/register/change-success",
    component: _4ddc2450,
    pathToRegexpOptions: {"strict":true},
    name: "register-change-success___se"
  }, {
    path: "/se/register/dimoco",
    component: _68ec2670,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco___se"
  }, {
    path: "/se/register/get-started",
    component: _57f75188,
    pathToRegexpOptions: {"strict":true},
    name: "register-get-started___se"
  }, {
    path: "/se/register/ideal",
    component: _8ecc085a,
    pathToRegexpOptions: {"strict":true},
    name: "register-ideal___se"
  }, {
    path: "/se/register/magic-link",
    component: _4ad6960c,
    pathToRegexpOptions: {"strict":true},
    name: "register-magic-link___se"
  }, {
    path: "/se/register/payment-mode",
    component: _93317f08,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-mode___se"
  }, {
    path: "/se/register/payment-success",
    component: _b688108c,
    pathToRegexpOptions: {"strict":true},
    name: "register-payment-success___se"
  }, {
    path: "/se/register/paypal",
    component: _7372f165,
    pathToRegexpOptions: {"strict":true},
    name: "register-paypal___se"
  }, {
    path: "/se/register/planform",
    component: _5b309b42,
    pathToRegexpOptions: {"strict":true},
    name: "register-planform___se"
  }, {
    path: "/se/register/planinfo",
    component: _7e71f56e,
    pathToRegexpOptions: {"strict":true},
    name: "register-planinfo___se"
  }, {
    path: "/se/register/returning-customer",
    component: _d71b91ea,
    pathToRegexpOptions: {"strict":true},
    name: "register-returning-customer___se"
  }, {
    path: "/se/register/sofort",
    component: _c23834ce,
    pathToRegexpOptions: {"strict":true},
    name: "register-sofort___se"
  }, {
    path: "/se/register/subscription-picker",
    component: _55f3e3bc,
    pathToRegexpOptions: {"strict":true},
    name: "register-subscription-picker___se"
  }, {
    path: "/se/register/trustly",
    component: _5f06e7e3,
    pathToRegexpOptions: {"strict":true},
    name: "register-trustly___se"
  }, {
    path: "/se/register/welcome",
    component: _0cc93240,
    pathToRegexpOptions: {"strict":true},
    name: "register-welcome___se"
  }, {
    path: "/se/subscriptions_faq/consumed-hours",
    component: _83d9ce54,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-consumed-hours___se"
  }, {
    path: "/se/subscriptions_faq/logged-out",
    component: _3f79a5ef,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions_faq-logged-out___se"
  }, {
    path: "/at-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___at-en"
  }, {
    path: "/at-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___at-en"
  }, {
    path: "/at-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___at-en"
  }, {
    path: "/at-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___at-en"
  }, {
    path: "/at-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___at-en"
  }, {
    path: "/at-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___at-en"
  }, {
    path: "/at-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___at-en"
  }, {
    path: "/at-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___at-en"
  }, {
    path: "/at-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___at-en"
  }, {
    path: "/at-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___at-en"
  }, {
    path: "/at-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___at-en"
  }, {
    path: "/at-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___at-en"
  }, {
    path: "/at-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___at-en"
  }, {
    path: "/at-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___at-en"
  }, {
    path: "/at-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___at-en"
  }, {
    path: "/at-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___at-en"
  }, {
    path: "/at-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___at-en"
  }, {
    path: "/at-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___at-en"
  }, {
    path: "/at-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___at-en"
  }, {
    path: "/at-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___at-en"
  }, {
    path: "/at/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___at"
  }, {
    path: "/at/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___at"
  }, {
    path: "/at/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___at"
  }, {
    path: "/at/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___at"
  }, {
    path: "/at/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___at"
  }, {
    path: "/at/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___at"
  }, {
    path: "/at/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___at"
  }, {
    path: "/at/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___at"
  }, {
    path: "/at/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___at"
  }, {
    path: "/at/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___at"
  }, {
    path: "/at/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___at"
  }, {
    path: "/at/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___at"
  }, {
    path: "/at/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___at"
  }, {
    path: "/at/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___at"
  }, {
    path: "/at/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___at"
  }, {
    path: "/at/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___at"
  }, {
    path: "/at/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___at"
  }, {
    path: "/at/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___at"
  }, {
    path: "/at/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___at"
  }, {
    path: "/at/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___at"
  }, {
    path: "/be-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___be-en"
  }, {
    path: "/be-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___be-en"
  }, {
    path: "/be-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___be-en"
  }, {
    path: "/be-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___be-en"
  }, {
    path: "/be-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___be-en"
  }, {
    path: "/be-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___be-en"
  }, {
    path: "/be-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___be-en"
  }, {
    path: "/be-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___be-en"
  }, {
    path: "/be-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___be-en"
  }, {
    path: "/be-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___be-en"
  }, {
    path: "/be-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___be-en"
  }, {
    path: "/be-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___be-en"
  }, {
    path: "/be-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___be-en"
  }, {
    path: "/be-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___be-en"
  }, {
    path: "/be-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___be-en"
  }, {
    path: "/be-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___be-en"
  }, {
    path: "/be-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___be-en"
  }, {
    path: "/be-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___be-en"
  }, {
    path: "/be-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___be-en"
  }, {
    path: "/be-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___be-en"
  }, {
    path: "/be-fr/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___be-fr"
  }, {
    path: "/be-fr/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___be-fr"
  }, {
    path: "/be-fr/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___be-fr"
  }, {
    path: "/be-fr/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___be-fr"
  }, {
    path: "/be-fr/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___be-fr"
  }, {
    path: "/be-fr/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___be-fr"
  }, {
    path: "/be-fr/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___be-fr"
  }, {
    path: "/be-fr/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___be-fr"
  }, {
    path: "/be-fr/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___be-fr"
  }, {
    path: "/be-fr/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___be-fr"
  }, {
    path: "/be-fr/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___be-fr"
  }, {
    path: "/be-fr/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___be-fr"
  }, {
    path: "/be-fr/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___be-fr"
  }, {
    path: "/be-fr/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___be-fr"
  }, {
    path: "/be-fr/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___be-fr"
  }, {
    path: "/be-fr/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___be-fr"
  }, {
    path: "/be-fr/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___be-fr"
  }, {
    path: "/be-fr/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___be-fr"
  }, {
    path: "/be-fr/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___be-fr"
  }, {
    path: "/be-fr/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___be-fr"
  }, {
    path: "/ch-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___ch-en"
  }, {
    path: "/ch-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___ch-en"
  }, {
    path: "/ch-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___ch-en"
  }, {
    path: "/ch-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___ch-en"
  }, {
    path: "/ch-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___ch-en"
  }, {
    path: "/ch-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___ch-en"
  }, {
    path: "/ch-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___ch-en"
  }, {
    path: "/ch-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___ch-en"
  }, {
    path: "/ch-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___ch-en"
  }, {
    path: "/ch-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___ch-en"
  }, {
    path: "/ch-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___ch-en"
  }, {
    path: "/ch-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___ch-en"
  }, {
    path: "/ch-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___ch-en"
  }, {
    path: "/ch-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___ch-en"
  }, {
    path: "/ch-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___ch-en"
  }, {
    path: "/ch-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___ch-en"
  }, {
    path: "/ch-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___ch-en"
  }, {
    path: "/ch-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___ch-en"
  }, {
    path: "/ch-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___ch-en"
  }, {
    path: "/ch-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___ch-en"
  }, {
    path: "/ch/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___ch"
  }, {
    path: "/ch/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___ch"
  }, {
    path: "/ch/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___ch"
  }, {
    path: "/ch/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___ch"
  }, {
    path: "/ch/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___ch"
  }, {
    path: "/ch/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___ch"
  }, {
    path: "/ch/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___ch"
  }, {
    path: "/ch/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___ch"
  }, {
    path: "/ch/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___ch"
  }, {
    path: "/ch/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___ch"
  }, {
    path: "/ch/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___ch"
  }, {
    path: "/ch/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___ch"
  }, {
    path: "/ch/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___ch"
  }, {
    path: "/ch/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___ch"
  }, {
    path: "/ch/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___ch"
  }, {
    path: "/ch/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___ch"
  }, {
    path: "/ch/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___ch"
  }, {
    path: "/ch/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___ch"
  }, {
    path: "/ch/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___ch"
  }, {
    path: "/ch/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___ch"
  }, {
    path: "/de-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___de-en"
  }, {
    path: "/de-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___de-en"
  }, {
    path: "/de-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___de-en"
  }, {
    path: "/de-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___de-en"
  }, {
    path: "/de-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___de-en"
  }, {
    path: "/de-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___de-en"
  }, {
    path: "/de-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___de-en"
  }, {
    path: "/de-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___de-en"
  }, {
    path: "/de-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___de-en"
  }, {
    path: "/de-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___de-en"
  }, {
    path: "/de-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___de-en"
  }, {
    path: "/de-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___de-en"
  }, {
    path: "/de-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___de-en"
  }, {
    path: "/de-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___de-en"
  }, {
    path: "/de-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___de-en"
  }, {
    path: "/de-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___de-en"
  }, {
    path: "/de-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___de-en"
  }, {
    path: "/de-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___de-en"
  }, {
    path: "/de-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___de-en"
  }, {
    path: "/de-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___de-en"
  }, {
    path: "/de/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___de"
  }, {
    path: "/de/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___de"
  }, {
    path: "/de/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___de"
  }, {
    path: "/de/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___de"
  }, {
    path: "/de/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___de"
  }, {
    path: "/de/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___de"
  }, {
    path: "/de/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___de"
  }, {
    path: "/de/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___de"
  }, {
    path: "/de/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___de"
  }, {
    path: "/de/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___de"
  }, {
    path: "/de/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___de"
  }, {
    path: "/de/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___de"
  }, {
    path: "/de/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___de"
  }, {
    path: "/de/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___de"
  }, {
    path: "/de/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___de"
  }, {
    path: "/de/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___de"
  }, {
    path: "/de/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___de"
  }, {
    path: "/de/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___de"
  }, {
    path: "/de/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___de"
  }, {
    path: "/de/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___de"
  }, {
    path: "/dk-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___dk-en"
  }, {
    path: "/dk-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___dk-en"
  }, {
    path: "/dk-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___dk-en"
  }, {
    path: "/dk-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___dk-en"
  }, {
    path: "/dk-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___dk-en"
  }, {
    path: "/dk-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___dk-en"
  }, {
    path: "/dk-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___dk-en"
  }, {
    path: "/dk-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___dk-en"
  }, {
    path: "/dk-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___dk-en"
  }, {
    path: "/dk-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___dk-en"
  }, {
    path: "/dk-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___dk-en"
  }, {
    path: "/dk-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___dk-en"
  }, {
    path: "/dk-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___dk-en"
  }, {
    path: "/dk-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___dk-en"
  }, {
    path: "/dk-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___dk-en"
  }, {
    path: "/dk-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___dk-en"
  }, {
    path: "/dk-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___dk-en"
  }, {
    path: "/dk-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___dk-en"
  }, {
    path: "/dk-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___dk-en"
  }, {
    path: "/dk-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___dk-en"
  }, {
    path: "/dk/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___dk"
  }, {
    path: "/dk/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___dk"
  }, {
    path: "/dk/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___dk"
  }, {
    path: "/dk/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___dk"
  }, {
    path: "/dk/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___dk"
  }, {
    path: "/dk/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___dk"
  }, {
    path: "/dk/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___dk"
  }, {
    path: "/dk/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___dk"
  }, {
    path: "/dk/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___dk"
  }, {
    path: "/dk/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___dk"
  }, {
    path: "/dk/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___dk"
  }, {
    path: "/dk/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___dk"
  }, {
    path: "/dk/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___dk"
  }, {
    path: "/dk/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___dk"
  }, {
    path: "/dk/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___dk"
  }, {
    path: "/dk/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___dk"
  }, {
    path: "/dk/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___dk"
  }, {
    path: "/dk/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___dk"
  }, {
    path: "/dk/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___dk"
  }, {
    path: "/dk/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___dk"
  }, {
    path: "/es-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___es-en"
  }, {
    path: "/es-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___es-en"
  }, {
    path: "/es-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___es-en"
  }, {
    path: "/es-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___es-en"
  }, {
    path: "/es-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___es-en"
  }, {
    path: "/es-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___es-en"
  }, {
    path: "/es-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___es-en"
  }, {
    path: "/es-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___es-en"
  }, {
    path: "/es-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___es-en"
  }, {
    path: "/es-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___es-en"
  }, {
    path: "/es-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___es-en"
  }, {
    path: "/es-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___es-en"
  }, {
    path: "/es-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___es-en"
  }, {
    path: "/es-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___es-en"
  }, {
    path: "/es-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___es-en"
  }, {
    path: "/es-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___es-en"
  }, {
    path: "/es-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___es-en"
  }, {
    path: "/es-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___es-en"
  }, {
    path: "/es-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___es-en"
  }, {
    path: "/es-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___es-en"
  }, {
    path: "/es/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___es"
  }, {
    path: "/es/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___es"
  }, {
    path: "/es/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___es"
  }, {
    path: "/es/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___es"
  }, {
    path: "/es/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___es"
  }, {
    path: "/es/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___es"
  }, {
    path: "/es/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___es"
  }, {
    path: "/es/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___es"
  }, {
    path: "/es/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___es"
  }, {
    path: "/es/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___es"
  }, {
    path: "/es/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___es"
  }, {
    path: "/es/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___es"
  }, {
    path: "/es/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___es"
  }, {
    path: "/es/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___es"
  }, {
    path: "/es/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___es"
  }, {
    path: "/es/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___es"
  }, {
    path: "/es/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___es"
  }, {
    path: "/es/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___es"
  }, {
    path: "/es/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___es"
  }, {
    path: "/es/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___es"
  }, {
    path: "/fi-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___fi-en"
  }, {
    path: "/fi-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___fi-en"
  }, {
    path: "/fi-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___fi-en"
  }, {
    path: "/fi-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___fi-en"
  }, {
    path: "/fi-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___fi-en"
  }, {
    path: "/fi-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___fi-en"
  }, {
    path: "/fi-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___fi-en"
  }, {
    path: "/fi-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___fi-en"
  }, {
    path: "/fi-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___fi-en"
  }, {
    path: "/fi-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___fi-en"
  }, {
    path: "/fi-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___fi-en"
  }, {
    path: "/fi-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___fi-en"
  }, {
    path: "/fi-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___fi-en"
  }, {
    path: "/fi-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___fi-en"
  }, {
    path: "/fi-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___fi-en"
  }, {
    path: "/fi-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___fi-en"
  }, {
    path: "/fi-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___fi-en"
  }, {
    path: "/fi-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___fi-en"
  }, {
    path: "/fi-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___fi-en"
  }, {
    path: "/fi-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___fi-en"
  }, {
    path: "/fi/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___fi"
  }, {
    path: "/fi/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___fi"
  }, {
    path: "/fi/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___fi"
  }, {
    path: "/fi/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___fi"
  }, {
    path: "/fi/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___fi"
  }, {
    path: "/fi/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___fi"
  }, {
    path: "/fi/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___fi"
  }, {
    path: "/fi/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___fi"
  }, {
    path: "/fi/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___fi"
  }, {
    path: "/fi/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___fi"
  }, {
    path: "/fi/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___fi"
  }, {
    path: "/fi/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___fi"
  }, {
    path: "/fi/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___fi"
  }, {
    path: "/fi/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___fi"
  }, {
    path: "/fi/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___fi"
  }, {
    path: "/fi/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___fi"
  }, {
    path: "/fi/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___fi"
  }, {
    path: "/fi/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___fi"
  }, {
    path: "/fi/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___fi"
  }, {
    path: "/fi/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___fi"
  }, {
    path: "/fr-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___fr-en"
  }, {
    path: "/fr-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___fr-en"
  }, {
    path: "/fr-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___fr-en"
  }, {
    path: "/fr-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___fr-en"
  }, {
    path: "/fr-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___fr-en"
  }, {
    path: "/fr-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___fr-en"
  }, {
    path: "/fr-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___fr-en"
  }, {
    path: "/fr-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___fr-en"
  }, {
    path: "/fr-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___fr-en"
  }, {
    path: "/fr-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___fr-en"
  }, {
    path: "/fr-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___fr-en"
  }, {
    path: "/fr-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___fr-en"
  }, {
    path: "/fr-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___fr-en"
  }, {
    path: "/fr-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___fr-en"
  }, {
    path: "/fr-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___fr-en"
  }, {
    path: "/fr-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___fr-en"
  }, {
    path: "/fr-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___fr-en"
  }, {
    path: "/fr-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___fr-en"
  }, {
    path: "/fr-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___fr-en"
  }, {
    path: "/fr-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___fr-en"
  }, {
    path: "/fr/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___fr"
  }, {
    path: "/fr/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___fr"
  }, {
    path: "/fr/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___fr"
  }, {
    path: "/fr/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___fr"
  }, {
    path: "/fr/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___fr"
  }, {
    path: "/fr/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___fr"
  }, {
    path: "/fr/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___fr"
  }, {
    path: "/fr/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___fr"
  }, {
    path: "/fr/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___fr"
  }, {
    path: "/fr/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___fr"
  }, {
    path: "/fr/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___fr"
  }, {
    path: "/fr/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___fr"
  }, {
    path: "/fr/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___fr"
  }, {
    path: "/fr/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___fr"
  }, {
    path: "/fr/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___fr"
  }, {
    path: "/fr/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___fr"
  }, {
    path: "/fr/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___fr"
  }, {
    path: "/fr/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___fr"
  }, {
    path: "/fr/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___fr"
  }, {
    path: "/fr/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___fr"
  }, {
    path: "/nl-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___nl-en"
  }, {
    path: "/nl-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___nl-en"
  }, {
    path: "/nl-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___nl-en"
  }, {
    path: "/nl-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___nl-en"
  }, {
    path: "/nl-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___nl-en"
  }, {
    path: "/nl-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___nl-en"
  }, {
    path: "/nl-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___nl-en"
  }, {
    path: "/nl-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___nl-en"
  }, {
    path: "/nl-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___nl-en"
  }, {
    path: "/nl-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___nl-en"
  }, {
    path: "/nl-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___nl-en"
  }, {
    path: "/nl-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___nl-en"
  }, {
    path: "/nl-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___nl-en"
  }, {
    path: "/nl-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___nl-en"
  }, {
    path: "/nl-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___nl-en"
  }, {
    path: "/nl-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___nl-en"
  }, {
    path: "/nl-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___nl-en"
  }, {
    path: "/nl-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___nl-en"
  }, {
    path: "/nl-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___nl-en"
  }, {
    path: "/nl-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___nl-en"
  }, {
    path: "/nl/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___nl"
  }, {
    path: "/nl/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___nl"
  }, {
    path: "/nl/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___nl"
  }, {
    path: "/nl/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___nl"
  }, {
    path: "/nl/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___nl"
  }, {
    path: "/nl/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___nl"
  }, {
    path: "/nl/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___nl"
  }, {
    path: "/nl/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___nl"
  }, {
    path: "/nl/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___nl"
  }, {
    path: "/nl/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___nl"
  }, {
    path: "/nl/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___nl"
  }, {
    path: "/nl/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___nl"
  }, {
    path: "/nl/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___nl"
  }, {
    path: "/nl/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___nl"
  }, {
    path: "/nl/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___nl"
  }, {
    path: "/nl/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___nl"
  }, {
    path: "/nl/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___nl"
  }, {
    path: "/nl/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___nl"
  }, {
    path: "/nl/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___nl"
  }, {
    path: "/nl/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___nl"
  }, {
    path: "/no-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___no-en"
  }, {
    path: "/no-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___no-en"
  }, {
    path: "/no-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___no-en"
  }, {
    path: "/no-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___no-en"
  }, {
    path: "/no-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___no-en"
  }, {
    path: "/no-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___no-en"
  }, {
    path: "/no-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___no-en"
  }, {
    path: "/no-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___no-en"
  }, {
    path: "/no-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___no-en"
  }, {
    path: "/no-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___no-en"
  }, {
    path: "/no-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___no-en"
  }, {
    path: "/no-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___no-en"
  }, {
    path: "/no-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___no-en"
  }, {
    path: "/no-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___no-en"
  }, {
    path: "/no-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___no-en"
  }, {
    path: "/no-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___no-en"
  }, {
    path: "/no-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___no-en"
  }, {
    path: "/no-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___no-en"
  }, {
    path: "/no-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___no-en"
  }, {
    path: "/no-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___no-en"
  }, {
    path: "/no/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___no"
  }, {
    path: "/no/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___no"
  }, {
    path: "/no/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___no"
  }, {
    path: "/no/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___no"
  }, {
    path: "/no/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___no"
  }, {
    path: "/no/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___no"
  }, {
    path: "/no/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___no"
  }, {
    path: "/no/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___no"
  }, {
    path: "/no/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___no"
  }, {
    path: "/no/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___no"
  }, {
    path: "/no/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___no"
  }, {
    path: "/no/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___no"
  }, {
    path: "/no/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___no"
  }, {
    path: "/no/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___no"
  }, {
    path: "/no/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___no"
  }, {
    path: "/no/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___no"
  }, {
    path: "/no/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___no"
  }, {
    path: "/no/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___no"
  }, {
    path: "/no/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___no"
  }, {
    path: "/no/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___no"
  }, {
    path: "/se-en/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___se-en"
  }, {
    path: "/se-en/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___se-en"
  }, {
    path: "/se-en/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___se-en"
  }, {
    path: "/se-en/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___se-en"
  }, {
    path: "/se-en/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___se-en"
  }, {
    path: "/se-en/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___se-en"
  }, {
    path: "/se-en/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___se-en"
  }, {
    path: "/se-en/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___se-en"
  }, {
    path: "/se-en/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___se-en"
  }, {
    path: "/se-en/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___se-en"
  }, {
    path: "/se-en/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___se-en"
  }, {
    path: "/se-en/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___se-en"
  }, {
    path: "/se-en/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___se-en"
  }, {
    path: "/se-en/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___se-en"
  }, {
    path: "/se-en/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___se-en"
  }, {
    path: "/se-en/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___se-en"
  }, {
    path: "/se-en/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___se-en"
  }, {
    path: "/se-en/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___se-en"
  }, {
    path: "/se-en/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___se-en"
  }, {
    path: "/se-en/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___se-en"
  }, {
    path: "/se/account/subscription/update",
    component: _77ee6e9f,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscription-update___se"
  }, {
    path: "/se/profile/cancel/disclaimer",
    component: _7fda1bf0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-disclaimer___se"
  }, {
    path: "/se/profile/cancel/downgrade-success",
    component: _3f90cbe6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-downgrade-success___se"
  }, {
    path: "/se/profile/cancel/extend-trial-success",
    component: _7c48b424,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-extend-trial-success___se"
  }, {
    path: "/se/profile/cancel/offer",
    component: _e8f718d2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-offer___se"
  }, {
    path: "/se/profile/cancel/reasons",
    component: _1dce11aa,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-reasons___se"
  }, {
    path: "/se/profile/cancel/revert-cancel",
    component: _cda3bd84,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-revert-cancel___se"
  }, {
    path: "/se/profile/cancel/stay-another-month-success",
    component: _57542466,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-stay-another-month-success___se"
  }, {
    path: "/se/profile/cancel/success",
    component: _cd6c5e04,
    pathToRegexpOptions: {"strict":true},
    name: "profile-cancel-success___se"
  }, {
    path: "/se/profile/providers/free",
    component: _62f9e5c9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-free___se"
  }, {
    path: "/se/profile/providers/orange",
    component: _8116b16a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange___se"
  }, {
    path: "/se/profile/providers/orange-tn",
    component: _a1636e28,
    pathToRegexpOptions: {"strict":true},
    name: "profile-providers-orange-tn___se"
  }, {
    path: "/se/profile/reset-password/success",
    component: _f8ed0546,
    pathToRegexpOptions: {"strict":true},
    name: "profile-reset-password-success___se"
  }, {
    path: "/se/register/campaign/activate",
    component: _15573310,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-activate___se"
  }, {
    path: "/se/register/campaign/validate",
    component: _c600a49a,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-validate___se"
  }, {
    path: "/se/register/dimoco/challenge",
    component: _9e0a9e4e,
    pathToRegexpOptions: {"strict":true},
    name: "register-dimoco-challenge___se"
  }, {
    path: "/se/register/giftcard/activate",
    component: _d41dd440,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-activate___se"
  }, {
    path: "/se/register/giftcard/info",
    component: _c3c4faca,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-info___se"
  }, {
    path: "/se/register/giftcard/success",
    component: _47dc2aa6,
    pathToRegexpOptions: {"strict":true},
    name: "register-giftcard-success___se"
  }, {
    path: "/se/register/partner/thank-you",
    component: _8856169a,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-thank-you___se"
  }, {
    path: "/at-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___at-en"
  }, {
    path: "/at-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___at-en"
  }, {
    path: "/at-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___at-en"
  }, {
    path: "/at-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___at-en"
  }, {
    path: "/at-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___at-en"
  }, {
    path: "/at-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___at-en"
  }, {
    path: "/at-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___at-en"
  }, {
    path: "/at/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___at"
  }, {
    path: "/at/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___at"
  }, {
    path: "/at/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___at"
  }, {
    path: "/at/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___at"
  }, {
    path: "/at/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___at"
  }, {
    path: "/at/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___at"
  }, {
    path: "/at/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___at"
  }, {
    path: "/be-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___be-en"
  }, {
    path: "/be-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___be-en"
  }, {
    path: "/be-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___be-en"
  }, {
    path: "/be-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___be-en"
  }, {
    path: "/be-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___be-en"
  }, {
    path: "/be-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___be-en"
  }, {
    path: "/be-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___be-en"
  }, {
    path: "/be-fr/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___be-fr"
  }, {
    path: "/be-fr/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___be-fr"
  }, {
    path: "/be-fr/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___be-fr"
  }, {
    path: "/be-fr/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___be-fr"
  }, {
    path: "/be-fr/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___be-fr"
  }, {
    path: "/be-fr/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___be-fr"
  }, {
    path: "/be-fr/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___be-fr"
  }, {
    path: "/ch-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___ch-en"
  }, {
    path: "/ch-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___ch-en"
  }, {
    path: "/ch-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___ch-en"
  }, {
    path: "/ch-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___ch-en"
  }, {
    path: "/ch-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___ch-en"
  }, {
    path: "/ch-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___ch-en"
  }, {
    path: "/ch-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___ch-en"
  }, {
    path: "/ch/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___ch"
  }, {
    path: "/ch/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___ch"
  }, {
    path: "/ch/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___ch"
  }, {
    path: "/ch/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___ch"
  }, {
    path: "/ch/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___ch"
  }, {
    path: "/ch/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___ch"
  }, {
    path: "/ch/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___ch"
  }, {
    path: "/de-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___de-en"
  }, {
    path: "/de-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___de-en"
  }, {
    path: "/de-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___de-en"
  }, {
    path: "/de-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___de-en"
  }, {
    path: "/de-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___de-en"
  }, {
    path: "/de-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___de-en"
  }, {
    path: "/de-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___de-en"
  }, {
    path: "/de/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___de"
  }, {
    path: "/de/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___de"
  }, {
    path: "/de/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___de"
  }, {
    path: "/de/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___de"
  }, {
    path: "/de/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___de"
  }, {
    path: "/de/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___de"
  }, {
    path: "/de/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___de"
  }, {
    path: "/dk-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___dk-en"
  }, {
    path: "/dk-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___dk-en"
  }, {
    path: "/dk-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___dk-en"
  }, {
    path: "/dk-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___dk-en"
  }, {
    path: "/dk-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___dk-en"
  }, {
    path: "/dk-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___dk-en"
  }, {
    path: "/dk-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___dk-en"
  }, {
    path: "/dk/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___dk"
  }, {
    path: "/dk/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___dk"
  }, {
    path: "/dk/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___dk"
  }, {
    path: "/dk/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___dk"
  }, {
    path: "/dk/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___dk"
  }, {
    path: "/dk/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___dk"
  }, {
    path: "/dk/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___dk"
  }, {
    path: "/es-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___es-en"
  }, {
    path: "/es-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___es-en"
  }, {
    path: "/es-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___es-en"
  }, {
    path: "/es-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___es-en"
  }, {
    path: "/es-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___es-en"
  }, {
    path: "/es-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___es-en"
  }, {
    path: "/es-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___es-en"
  }, {
    path: "/es/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___es"
  }, {
    path: "/es/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___es"
  }, {
    path: "/es/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___es"
  }, {
    path: "/es/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___es"
  }, {
    path: "/es/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___es"
  }, {
    path: "/es/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___es"
  }, {
    path: "/es/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___es"
  }, {
    path: "/fi-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___fi-en"
  }, {
    path: "/fi-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___fi-en"
  }, {
    path: "/fi-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___fi-en"
  }, {
    path: "/fi-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___fi-en"
  }, {
    path: "/fi-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___fi-en"
  }, {
    path: "/fi-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___fi-en"
  }, {
    path: "/fi-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___fi-en"
  }, {
    path: "/fi/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___fi"
  }, {
    path: "/fi/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___fi"
  }, {
    path: "/fi/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___fi"
  }, {
    path: "/fi/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___fi"
  }, {
    path: "/fi/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___fi"
  }, {
    path: "/fi/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___fi"
  }, {
    path: "/fi/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___fi"
  }, {
    path: "/fr-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___fr-en"
  }, {
    path: "/fr-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___fr-en"
  }, {
    path: "/fr-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___fr-en"
  }, {
    path: "/fr-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___fr-en"
  }, {
    path: "/fr-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___fr-en"
  }, {
    path: "/fr-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___fr-en"
  }, {
    path: "/fr-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___fr-en"
  }, {
    path: "/fr/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___fr"
  }, {
    path: "/fr/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___fr"
  }, {
    path: "/fr/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___fr"
  }, {
    path: "/fr/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___fr"
  }, {
    path: "/fr/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___fr"
  }, {
    path: "/fr/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___fr"
  }, {
    path: "/fr/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___fr"
  }, {
    path: "/nl-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___nl-en"
  }, {
    path: "/nl-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___nl-en"
  }, {
    path: "/nl-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___nl-en"
  }, {
    path: "/nl-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___nl-en"
  }, {
    path: "/nl-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___nl-en"
  }, {
    path: "/nl-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___nl-en"
  }, {
    path: "/nl-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___nl-en"
  }, {
    path: "/nl/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___nl"
  }, {
    path: "/nl/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___nl"
  }, {
    path: "/nl/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___nl"
  }, {
    path: "/nl/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___nl"
  }, {
    path: "/nl/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___nl"
  }, {
    path: "/nl/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___nl"
  }, {
    path: "/nl/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___nl"
  }, {
    path: "/no-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___no-en"
  }, {
    path: "/no-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___no-en"
  }, {
    path: "/no-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___no-en"
  }, {
    path: "/no-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___no-en"
  }, {
    path: "/no-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___no-en"
  }, {
    path: "/no-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___no-en"
  }, {
    path: "/no-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___no-en"
  }, {
    path: "/no/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___no"
  }, {
    path: "/no/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___no"
  }, {
    path: "/no/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___no"
  }, {
    path: "/no/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___no"
  }, {
    path: "/no/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___no"
  }, {
    path: "/no/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___no"
  }, {
    path: "/no/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___no"
  }, {
    path: "/se-en/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___se-en"
  }, {
    path: "/se-en/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___se-en"
  }, {
    path: "/se-en/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___se-en"
  }, {
    path: "/se-en/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___se-en"
  }, {
    path: "/se-en/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___se-en"
  }, {
    path: "/se-en/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___se-en"
  }, {
    path: "/se-en/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___se-en"
  }, {
    path: "/se/category/books/:slug?",
    component: _7f4e4b3e,
    pathToRegexpOptions: {"strict":true},
    name: "category-books-slug___se"
  }, {
    path: "/se/category/magazines/:slug?",
    component: _5fd66d9a,
    pathToRegexpOptions: {"strict":true},
    name: "category-magazines-slug___se"
  }, {
    path: "/se/collab/contact/:slug?",
    component: _7be2427a,
    pathToRegexpOptions: {"strict":true},
    name: "collab-contact-slug___se"
  }, {
    path: "/se/migration/book/:id?",
    component: _882a9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "migration-book-id___se"
  }, {
    path: "/se/migration/category/:slug?",
    component: _35293684,
    pathToRegexpOptions: {"strict":true},
    name: "migration-category-slug___se"
  }, {
    path: "/se/register/campaign/:slug?",
    component: _0593ca3d,
    pathToRegexpOptions: {"strict":true},
    name: "register-campaign-slug___se"
  }, {
    path: "/se/register/partner/:channel",
    component: _fef65840,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel___se"
  }, {
    path: "/at-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___at-en"
  }, {
    path: "/at/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___at"
  }, {
    path: "/be-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___be-en"
  }, {
    path: "/be-fr/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___be-fr"
  }, {
    path: "/ch-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___ch-en"
  }, {
    path: "/ch/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___ch"
  }, {
    path: "/de-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___de-en"
  }, {
    path: "/de/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___de"
  }, {
    path: "/dk-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___dk-en"
  }, {
    path: "/dk/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___dk"
  }, {
    path: "/es-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___es-en"
  }, {
    path: "/es/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___es"
  }, {
    path: "/fi-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___fi-en"
  }, {
    path: "/fi/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___fi"
  }, {
    path: "/fr-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___fr-en"
  }, {
    path: "/fr/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___fr"
  }, {
    path: "/nl-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___nl-en"
  }, {
    path: "/nl/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___nl"
  }, {
    path: "/no-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___no-en"
  }, {
    path: "/no/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___no"
  }, {
    path: "/se-en/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___se-en"
  }, {
    path: "/se/register/partner/:channel?/link",
    component: _448e1d5c,
    pathToRegexpOptions: {"strict":true},
    name: "register-partner-channel-link___se"
  }, {
    path: "/at-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___at-en"
  }, {
    path: "/at-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___at-en"
  }, {
    path: "/at-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___at-en"
  }, {
    path: "/at-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___at-en"
  }, {
    path: "/at-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___at-en"
  }, {
    path: "/at/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___at"
  }, {
    path: "/at/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___at"
  }, {
    path: "/at/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___at"
  }, {
    path: "/at/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___at"
  }, {
    path: "/at/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___at"
  }, {
    path: "/be-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___be-en"
  }, {
    path: "/be-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___be-en"
  }, {
    path: "/be-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___be-en"
  }, {
    path: "/be-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___be-en"
  }, {
    path: "/be-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___be-en"
  }, {
    path: "/be-fr/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___be-fr"
  }, {
    path: "/be-fr/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___be-fr"
  }, {
    path: "/be-fr/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___be-fr"
  }, {
    path: "/be-fr/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___be-fr"
  }, {
    path: "/be-fr/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___be-fr"
  }, {
    path: "/ch-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___ch-en"
  }, {
    path: "/ch-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___ch-en"
  }, {
    path: "/ch-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___ch-en"
  }, {
    path: "/ch-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___ch-en"
  }, {
    path: "/ch-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___ch-en"
  }, {
    path: "/ch/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___ch"
  }, {
    path: "/ch/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___ch"
  }, {
    path: "/ch/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___ch"
  }, {
    path: "/ch/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___ch"
  }, {
    path: "/ch/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___ch"
  }, {
    path: "/de-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___de-en"
  }, {
    path: "/de-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___de-en"
  }, {
    path: "/de-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___de-en"
  }, {
    path: "/de-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___de-en"
  }, {
    path: "/de-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___de-en"
  }, {
    path: "/de/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___de"
  }, {
    path: "/de/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___de"
  }, {
    path: "/de/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___de"
  }, {
    path: "/de/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___de"
  }, {
    path: "/de/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___de"
  }, {
    path: "/dk-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___dk-en"
  }, {
    path: "/dk-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___dk-en"
  }, {
    path: "/dk-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___dk-en"
  }, {
    path: "/dk-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___dk-en"
  }, {
    path: "/dk-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___dk-en"
  }, {
    path: "/dk/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___dk"
  }, {
    path: "/dk/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___dk"
  }, {
    path: "/dk/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___dk"
  }, {
    path: "/dk/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___dk"
  }, {
    path: "/dk/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___dk"
  }, {
    path: "/es-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___es-en"
  }, {
    path: "/es-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___es-en"
  }, {
    path: "/es-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___es-en"
  }, {
    path: "/es-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___es-en"
  }, {
    path: "/es-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___es-en"
  }, {
    path: "/es/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___es"
  }, {
    path: "/es/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___es"
  }, {
    path: "/es/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___es"
  }, {
    path: "/es/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___es"
  }, {
    path: "/es/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___es"
  }, {
    path: "/fi-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___fi-en"
  }, {
    path: "/fi-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___fi-en"
  }, {
    path: "/fi-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___fi-en"
  }, {
    path: "/fi-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___fi-en"
  }, {
    path: "/fi-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___fi-en"
  }, {
    path: "/fi/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___fi"
  }, {
    path: "/fi/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___fi"
  }, {
    path: "/fi/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___fi"
  }, {
    path: "/fi/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___fi"
  }, {
    path: "/fi/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___fi"
  }, {
    path: "/fr-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___fr-en"
  }, {
    path: "/fr-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___fr-en"
  }, {
    path: "/fr-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___fr-en"
  }, {
    path: "/fr-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___fr-en"
  }, {
    path: "/fr-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___fr-en"
  }, {
    path: "/fr/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___fr"
  }, {
    path: "/fr/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___fr"
  }, {
    path: "/fr/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___fr"
  }, {
    path: "/fr/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___fr"
  }, {
    path: "/fr/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___fr"
  }, {
    path: "/nl-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___nl-en"
  }, {
    path: "/nl-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___nl-en"
  }, {
    path: "/nl-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___nl-en"
  }, {
    path: "/nl-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___nl-en"
  }, {
    path: "/nl-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___nl-en"
  }, {
    path: "/nl/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___nl"
  }, {
    path: "/nl/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___nl"
  }, {
    path: "/nl/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___nl"
  }, {
    path: "/nl/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___nl"
  }, {
    path: "/nl/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___nl"
  }, {
    path: "/no-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___no-en"
  }, {
    path: "/no-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___no-en"
  }, {
    path: "/no-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___no-en"
  }, {
    path: "/no-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___no-en"
  }, {
    path: "/no-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___no-en"
  }, {
    path: "/no/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___no"
  }, {
    path: "/no/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___no"
  }, {
    path: "/no/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___no"
  }, {
    path: "/no/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___no"
  }, {
    path: "/no/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___no"
  }, {
    path: "/se-en/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___se-en"
  }, {
    path: "/se-en/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___se-en"
  }, {
    path: "/se-en/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___se-en"
  }, {
    path: "/se-en/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___se-en"
  }, {
    path: "/se-en/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___se-en"
  }, {
    path: "/se/author/:slug?",
    component: _28d999f0,
    pathToRegexpOptions: {"strict":true},
    name: "author-slug___se"
  }, {
    path: "/se/book/:slug?",
    component: _5ecbfb64,
    pathToRegexpOptions: {"strict":true},
    name: "book-slug___se"
  }, {
    path: "/se/content/:slug?",
    component: _2a1b1cfa,
    pathToRegexpOptions: {"strict":true},
    name: "content-slug___se"
  }, {
    path: "/se/narrator/:slug?",
    component: _eeac2d24,
    pathToRegexpOptions: {"strict":true},
    name: "narrator-slug___se"
  }, {
    path: "/se/series/:slug?",
    component: _4bbf8048,
    pathToRegexpOptions: {"strict":true},
    name: "series-slug___se"
  }, {
    path: "/at-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___at-en"
  }, {
    path: "/at/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___at"
  }, {
    path: "/be-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___be-en"
  }, {
    path: "/be-fr/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___be-fr"
  }, {
    path: "/ch-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___ch-en"
  }, {
    path: "/ch/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___ch"
  }, {
    path: "/de-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___de-en"
  }, {
    path: "/de/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___de"
  }, {
    path: "/dk-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___dk-en"
  }, {
    path: "/dk/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___dk"
  }, {
    path: "/es-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___es-en"
  }, {
    path: "/es/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___es"
  }, {
    path: "/fi-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___fi-en"
  }, {
    path: "/fi/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___fi"
  }, {
    path: "/fr-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___fr-en"
  }, {
    path: "/fr/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___fr"
  }, {
    path: "/nl-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___nl-en"
  }, {
    path: "/nl/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___nl"
  }, {
    path: "/no-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___no-en"
  }, {
    path: "/no/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___no"
  }, {
    path: "/se-en/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___se-en"
  }, {
    path: "/se/collab/:type?/:slug?",
    component: _442c137c,
    pathToRegexpOptions: {"strict":true},
    name: "collab-type-slug___se"
  }, {
    path: "/at-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___at-en"
  }, {
    path: "/at/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___at"
  }, {
    path: "/be-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___be-en"
  }, {
    path: "/be-fr/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___be-fr"
  }, {
    path: "/ch-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___ch-en"
  }, {
    path: "/ch/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___ch"
  }, {
    path: "/de-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___de-en"
  }, {
    path: "/de/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___de"
  }, {
    path: "/dk-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___dk-en"
  }, {
    path: "/dk/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___dk"
  }, {
    path: "/es-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___es-en"
  }, {
    path: "/es/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___es"
  }, {
    path: "/fi-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___fi-en"
  }, {
    path: "/fi/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___fi"
  }, {
    path: "/fr-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___fr-en"
  }, {
    path: "/fr/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___fr"
  }, {
    path: "/nl-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___nl-en"
  }, {
    path: "/nl/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___nl"
  }, {
    path: "/no-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___no-en"
  }, {
    path: "/no/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___no"
  }, {
    path: "/se-en/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___se-en"
  }, {
    path: "/se/:slug",
    component: _2c447510,
    pathToRegexpOptions: {"strict":true},
    name: "slug___se"
  }, {
    path: "/at-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___at-en"
  }, {
    path: "/at/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___at"
  }, {
    path: "/be-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___be-en"
  }, {
    path: "/be-fr/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___be-fr"
  }, {
    path: "/ch-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___ch-en"
  }, {
    path: "/ch/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___ch"
  }, {
    path: "/de-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___de-en"
  }, {
    path: "/de/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___de"
  }, {
    path: "/dk-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___dk-en"
  }, {
    path: "/dk/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___dk"
  }, {
    path: "/es-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___es-en"
  }, {
    path: "/es/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___es"
  }, {
    path: "/fi-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___fi-en"
  }, {
    path: "/fi/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___fi"
  }, {
    path: "/fr-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___fr-en"
  }, {
    path: "/fr/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___fr"
  }, {
    path: "/nl-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___nl-en"
  }, {
    path: "/nl/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___nl"
  }, {
    path: "/no-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___no-en"
  }, {
    path: "/no/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___no"
  }, {
    path: "/se-en/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___se-en"
  }, {
    path: "/se/:iso/partner-collaboration/:slug?",
    component: _1c3400dc,
    pathToRegexpOptions: {"strict":true},
    name: "iso-partner-collaboration-slug___se"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
