import { AxiosPromise } from 'axios'

import { Category } from '../api-types/CatalogCategory'
import { SubCategory } from '../api-types/CatalogSubCategory'
import { Product, Comment } from '../api-types/CatalogProduct'

import BaseApi from '../bridge-api/baseApi'
import { CatalogAutoComplete } from '../api-types/CatalogAutoComplete'

/**
 * Recursively removes duplicate categories from the categories array.
 * @param categories The array of categories to clean.
 * @returns The cleaned array of categories.
 */
function getCleanedCategories(categories: SubCategory[]): SubCategory[] {
  // Use the `map()` method to iterate over the categories array and return a new array of cleaned categories.
  return categories.map(category => {
    // Recursively clean the subcategories of the current category.
    const cleanedSubcategories = getCleanedCategories(category.sub_categories)

    // Return a new category object with the cleaned subcategories.
    return {
      ...category,
      // Use the `filter()` method to remove any subcategory with the same ID as the current category.
      sub_categories: cleanedSubcategories.filter(
        subcategory => subcategory.id !== category.id
      ),
    }
  })
}

export class Catalog extends BaseApi {
  getBook(productId: number): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/library/v1/products/${productId}`,
      headers: {
        // TODO: Back-end fails with an error 500 when we have an X-Login-Token header without X-Profile-Token
        common: {
          'X-Login-Token': '',
        },
      },
    })
  }

  getAuthor(id: number): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/author/${id}`,
    })
  }

  getNarrator(id: number): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/narrator/${id}`,
    })
  }

  getSeries(id: number): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/series/${id}`,
    })
  }

  getPopularSeries(
    format: 'ebook' | 'audiobook',
    page: number = 0,
    per: number = 12
  ): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/popular/series?format=${format}&page=${page}&per=${per}`,
    })
  }

  getPopularMagazines(page: number = 0, per: number = 12): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/popular/magazines?page=${page}&per=${per}`,
    })
  }

  getCategories(
    contentType: 'book' | 'magazine',
    format: 'ebook' | 'audiobook' | 'ebook,audiobook' | '' = ''
  ): AxiosPromise<Category[]> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/categories?content_type=${contentType}&format=${format}`,
    }).then(response => {
      response.data.categories = getCleanedCategories(response.data.categories)
      return response
    })
  }

  getProductsByAuthor(
    id: number,
    page: number = 0,
    per: number = 48
  ): AxiosPromise<Product[]> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/authors/${id}/products?page=${page}&per=${per}`,
    })
  }

  getProductsByNarrator(
    id: number,
    page: number = 0,
    per: number = 48
  ): AxiosPromise<Product[]> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/narrators/${id}/products?page=${page}&per=${per}`,
    })
  }

  getProductsBySeries(
    id: number,
    type: string = 'book',
    page: number = 0,
    per: number = 48,
    sort:
      | 'volume'
      | 'relevance'
      | 'average_rating'
      | 'published_date'
      | 'title'
      | 'authors' = 'volume'
  ): AxiosPromise<Product[]> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/series/${id}/products?page=${page}&per=${per}&content_type=${type}&sort=${sort}`,
    })
  }

  getProductsByCategory(
    id: number,
    page: number = 0,
    per: number = 48,
    format: string = 'ebook,audiobook' // 'ebook' or 'audiobook' or 'ebook,audiobook'
  ): AxiosPromise<Product[]> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/categories/${id}/products?page=${page}&per=${per}&format=${format}`,
    })
  }

  getSeriesByCategory(
    id: string,
    page: number = 0,
    per: number = 48
  ): AxiosPromise<Category[]> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/categories/${id}/series?page=${page}&per=${per}`,
    })
  }

  getProductComments(
    productId: number,
    page: number = 0,
    per: number = 48
  ): AxiosPromise<Comment[]> {
    return this.http({
      method: 'get',
      url: `/library/v1/products/${productId}/comments?page=${page}&per=${per}`,
    })
  }

  getProductCategories(productId: number): AxiosPromise<Category[]> {
    return this.http({
      method: 'get',
      url: `/library/v1/products/${productId}/categories`,
    })
  }

  getProductCountries(productId: number): AxiosPromise<Category[]> {
    return this.http({
      method: 'get',
      url: `/library/v1/products/${productId}/countries`,
      headers: {
        // TODO: Back-end fails with an error 500 when we have an X-Login-Token header without X-Profile-Token
        common: {
          'X-Login-Token': '',
        },
      },
    })
  }

  searchBooks(
    searchPhrase: string,
    page: number = 0,
    per: number = 12,
    format: string = 'ebook,audiobook',
    sort: string = 'relevance'
  ): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/search/products/books?search_phrase=${encodeURIComponent(
        searchPhrase
      )}&page=${page}&per=${per}&format=${format}&sort=${sort}`,
    })
  }

  searchMagazines(
    searchPhrase: string,
    page: number = 0,
    per: number = 12,
    format: string = 'ebook,audiobook'
  ): AxiosPromise<any> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/search/products/magazines?search_phrase=${encodeURIComponent(
        searchPhrase
      )}&page=${page}&per=${per}&format=${format}`,
    })
  }

  /**
   * Autocomplete search phrase and return suggestions for books and magazines
   */
  autocomplete(search: string): AxiosPromise<CatalogAutoComplete> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/autocomplete?search_phrase=${encodeURIComponent(
        search
      )}`,
    })
  }

  /**
   * Used for the NX4 to NX5 migration.
   */
  getProductIdByBookId(bookId: number | string): AxiosPromise<{
    product_id: number
  }> {
    return this.http({
      method: 'get',
      url: `/library/v1/books/${bookId}/product`,
    })
  }

  /**
   * Used for the Youboox to NX5 migration.
   */
  getProductIdByYoubooxBookId(youbooxBookId: number | string): AxiosPromise<{
    product_id: number
  }> {
    return this.http({
      method: 'get',
      url: `/ybmigration/books/${youbooxBookId}/product`,
    })
  }

  getSearchEditorialEntries(page = 0, per = 12) {
    return this.http({
      method: 'get',
      url: `/discovery/v1/search/editorial_entries?page=${page}&per=${per}`,
    })
  }

  getSelectionProducts(id: number, page: number = 0, per: number = 48) {
    // FIXME: API has a bug where it returns one less than the requested amount for some countries
    // @see https://frescano.slack.com/archives/C056ZGJBQ8M/p1692719624015779
    per = per + 1

    return this.http({
      method: 'get',
      url: `/discovery/v1/selections/${id}/products?page=${page}&per=${per}`,
    })
  }
}
