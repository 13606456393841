export default function (to, from, savedPosition) {
  // we want to scroll to top when navigating to or from the register page
  // to ensure the user sees the top of the page
  if (to.path.includes('/register') || from.path.includes('/register')) {
    return { x: 0, y: 0 }
  }

  // Avoid scrolling to top when navigating to the search page
  if (to.path.includes('/search')) {
    return false
  }

  // Scroll behavior disabled for the catalog, author, series, narrator pages.
  if (
    to.path.includes('/category/books') ||
    to.path.includes('/category/magazines') ||
    to.path.includes('/author') ||
    to.path.includes('/series') ||
    to.path.includes('/narrator')
  ) {
    return false
  }

  // we keep the default behavior for all other pages
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}
