export interface MarketProperties {
  cta: string // Lokalize key (default: 'global.button_try_free')
  img: {
    homepage: {
      background_position?: string
      hero_desktop: string
      hero_mobile: string
      hero_tablet: string
      more_about_1: string
      more_about_2: string
      more_about_3: string
      phone_cover: string
      use_future_hero?: boolean
      want_more_desktop: string
      want_more_mobile: string
      want_more_tablet: string
    }
  }
  partners: {
    channel: string
    collect?: string // Lokalize key (default: 'partner.collect_discount.title')
    collect_body?: string // Lokalize key (default: 'partner.collect_points.body')
    collect_offer_details?: string // Lokalize key (default: 'campaign.collect_points.funnel.offer')
    email: {
      label: string
      placeholder: string
    }
    identifier: {
      errorMessage: string
      label: string
      placeholder: string
      required: boolean
      tooltip?: string
    }
    image: string
    loginCallback?: {
      // @see PartnerForm > loginCallbackUrl
      // Check NORWEGIAN integration for usage. We get the identifier through an external service after the user logs in there.
      // Un-encoded example of callback URL that can be generated: https://en.norwegianreward.com/login?returnUrl=http://nextory.com/no/register/partner/NORWEGIAN?id=[REWARD_NO]
      customPass?: (url: string) => string
      label: string
      route: {
        name: string
        params: {
          [key: string]: string
        }
        query: {
          [key: string]: string
        }
      }
      url: string
    }
    logo: {
      height: number
      src: string
      width: number
    }
    unit: string
  }[]
}

/**
 * Defaults are for French market. Cocorico!
 */
export function defaultProperties(): MarketProperties {
  return {
    cta: 'global.button_try_free',
    img: {
      homepage: {
        background_position: 'top',
        use_future_hero: false,
        hero_desktop: 'web/img/home/hero/fr/hero-fr-desktop.jpg',
        hero_mobile: 'web/img/home/hero/fr/hero-fr-mobile.jpg',
        hero_tablet: 'web/img/home/hero/fr/hero-fr-tablet.jpg',
        want_more_desktop: 'web/img/home/hero/fr/hero-fr-desktop.jpg',
        want_more_mobile: 'web/img/home/hero/fr/hero-fr-mobile.jpg',
        want_more_tablet: 'web/img/home/hero/fr/hero-fr-tablet.jpg',
        more_about_1: 'web/img/home/more-about-nx/fr/1-FR.jpg',
        more_about_2: 'web/img/home/more-about-nx/fr/2-FR.jpg',
        more_about_3: 'web/img/home/more-about-nx/fr/3-FR.jpg',
        phone_cover: 'web/img/home/phone/fr/phonebookcovers_FR.png',
      },
    },
    partners: [],
  }
}

const partnerNorwegian = {
  channel: 'NORWEGIAN',
  unit: 'mypages.collect_points.unit.norwegian',
  email: {
    label: 'regfunnel.create_account.input_label_email',
    placeholder: '',
  },
  identifier: {
    errorMessage: 'campaign.collect_points.funnel.error.wrong_identifyer',
    label: 'global.label.norwegian',
    placeholder: '',
    required: true,
  },
  image: 'web/img/partners/illustrations/norwegian_reward.jpg',
  logo: {
    src: 'web/img/partners/logos/norwegian_reward.png',
    height: 38,
    width: 150,
  },
  loginCallback: {
    // @see https://nextory.atlassian.net/wiki/spaces/NEX/pages/2252144641/Norwegian#Reward-Number-Retrieval-Feature
    url: `https://{country}.norwegianreward.com/login?returnUrl={returnUrl}`, // {returnUrl} will be replaced with our landing URL dynamically
    route: {
      name: 'register-partner-channel',
      params: {
        channel: 'NORWEGIAN',
      },
      query: {
        id: '[REWARD_NO]', // macro replaced by Norwegian during login
      },
    },
    label: 'mypages.collect_points.partner_callback.norwegian',
    customPass: (url: string) => {
      // Vue-router double encodes the [REWARD_NO] brackets, but Norwegian don't support it, so we need to "decode" them in a special way
      return url.replace(/%255B/g, '%5B').replace(/%255D/g, '%5D')
    },
  },
}

const partnerKLM = {
  channel: 'KLM',
  unit: 'mypages.collect_points.unit.klm',
  email: {
    label: 'regfunnel.create_account.input_label_email',
    placeholder: '',
  },
  identifier: {
    errorMessage: 'campaign.collect_points.funnel.error.wrong_identifyer',
    label: 'global.label.klm',
    placeholder: '',
    required: true,
  },
  image: 'web/img/partners/illustrations/klm.jpg',
  logo: {
    src: 'web/img/partners/logos/klm.png',
    height: 35, // this is the minimal height size
    width: 170, // this is the minimal width size
  },
}

/**
 * Only set market-specific data here, the rest will be filled with the defaults automatically in Hummingbird.
 */
export function propertiesPerMarkets(): Record<
  string,
  Partial<MarketProperties>
> {
  return {
    AT: {
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/dach/hero-dach-desktop.jpg',
          hero_mobile: 'web/img/home/hero/dach/hero-dach-mobile.jpg',
          hero_tablet: 'web/img/home/hero/dach/hero-dach-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/dach/hero-dach-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/dach/hero-dach-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/dach/hero-dach-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/dach/1-DACH.jpg',
          more_about_2: 'web/img/home/more-about-nx/dach/2-DACH.jpg',
          more_about_3: 'web/img/home/more-about-nx/dach/3-DACH.jpg',
          phone_cover: 'web/img/home/phone/dach/phonebookcovers_DACH.png',
        },
      },
    },
    BE: {},
    CH: {
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/dach/hero-dach-desktop.jpg',
          hero_mobile: 'web/img/home/hero/dach/hero-dach-mobile.jpg',
          hero_tablet: 'web/img/home/hero/dach/hero-dach-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/dach/hero-dach-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/dach/hero-dach-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/dach/hero-dach-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/dach/1-DACH.jpg',
          more_about_2: 'web/img/home/more-about-nx/dach/2-DACH.jpg',
          more_about_3: 'web/img/home/more-about-nx/dach/3-DACH.jpg',
          phone_cover: 'web/img/home/phone/dach/phonebookcovers_DACH.png',
        },
      },
    },
    DE: {
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/dach/hero-dach-desktop.jpg',
          hero_mobile: 'web/img/home/hero/dach/hero-dach-mobile.jpg',
          hero_tablet: 'web/img/home/hero/dach/hero-dach-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/dach/hero-dach-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/dach/hero-dach-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/dach/hero-dach-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/dach/1-DACH.jpg',
          more_about_2: 'web/img/home/more-about-nx/dach/2-DACH.jpg',
          more_about_3: 'web/img/home/more-about-nx/dach/3-DACH.jpg',
          phone_cover: 'web/img/home/phone/dach/phonebookcovers_DACH.png',
        },
      },
    },
    DK: {
      cta: 'homepage.hero.button.without_days',
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/dk/hero-dk-desktop.jpg',
          hero_mobile: 'web/img/home/hero/dk/hero-dk-mobile.jpg',
          hero_tablet: 'web/img/home/hero/dk/hero-dk-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/dk/hero-dk-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/dk/hero-dk-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/dk/hero-dk-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/dk/1-DK.jpg',
          more_about_2: 'web/img/home/more-about-nx/dk/2-DK.jpg',
          more_about_3: 'web/img/home/more-about-nx/dk/3-DK.jpg',
          phone_cover: 'web/img/home/phone/dk/phonebookcovers_DK.png',
        },
      },
      partners: [partnerNorwegian, partnerKLM],
    },
    ES: {
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/es/hero-es-desktop.jpg',
          hero_mobile: 'web/img/home/hero/es/hero-es-mobile.jpg',
          hero_tablet: 'web/img/home/hero/es/hero-es-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/es/hero-es-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/es/hero-es-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/es/hero-es-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/es/1-ES.jpg',
          more_about_2: 'web/img/home/more-about-nx/es/2-ES.jpg',
          more_about_3: 'web/img/home/more-about-nx/es/3-ES.jpg',
          phone_cover: 'web/img/home/phone/es/phonebookcovers_ES.png',
        },
      },
    },
    FI: {
      cta: 'homepage.hero.button.without_days',
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/fi/hero-fi-desktop.jpg',
          hero_mobile: 'web/img/home/hero/fi/hero-fi-mobile.jpg',
          hero_tablet: 'web/img/home/hero/fi/hero-fi-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/fi/hero-fi-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/fi/hero-fi-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/fi/hero-fi-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/fi/1-FI.jpg',
          more_about_2: 'web/img/home/more-about-nx/fi/2-FI.jpg',
          more_about_3: 'web/img/home/more-about-nx/fi/3-FI.jpg',
          phone_cover: 'web/img/home/phone/fi/phonebookcovers_FI.png',
        },
      },
      partners: [partnerNorwegian, partnerKLM],
    },
    FR: {
      /** @see defaultProperties() */
    },
    NL: {
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/nl/Grid_NL_desktop.jpg',
          hero_mobile: 'web/img/home/hero/nl/Grid_NL_mobile.jpg',
          hero_tablet: 'web/img/home/hero/nl/Grid_NL_tablet.jpg',
          want_more_desktop: 'web/img/home/hero/nl/Grid_NL_desktop.jpg',
          want_more_mobile: 'web/img/home/hero/nl/Grid_NL_mobile.jpg',
          want_more_tablet: 'web/img/home/hero/nl/Grid_NL_tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/nl/1_NL.png',
          more_about_2: 'web/img/home/more-about-nx/nl/2_NL.png',
          more_about_3: 'web/img/home/more-about-nx/nl/3_NL.jpg',
          phone_cover: 'web/img/home/phone/nl/phonebookcovers_NL.png',
        },
      },
    },
    NO: {
      cta: 'homepage.hero.button.without_days',
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/no/hero-no-desktop.jpg',
          hero_mobile: 'web/img/home/hero/no/hero-no-mobile.jpg',
          hero_tablet: 'web/img/home/hero/no/hero-no-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/no/hero-no-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/no/hero-no-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/no/hero-no-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/no/1-NO.jpg',
          more_about_2: 'web/img/home/more-about-nx/no/2-NO.jpg',
          more_about_3: 'web/img/home/more-about-nx/no/3-NO.jpg',
          phone_cover: 'web/img/home/phone/no/phonebookcovers_NO.png',
        },
      },
      partners: [
        {
          channel: 'BBL',
          unit: 'mypages.collect_points.unit.bbl',
          email: {
            label: 'regfunnel.create_account.input_label_email',
            placeholder: '',
          },
          identifier: {
            errorMessage:
              'campaign.collect_points.funnel.error.wrong_identifyer',
            label: 'global.label.bbl',
            placeholder: '',
            required: true,
          },
          image: 'web/img/partners/illustrations/bbl.jpg',
          logo: {
            src: 'web/img/partners/logos/bbl.png',
            height: 38,
            width: 155,
          },
        },

        partnerNorwegian,
        partnerKLM,
      ],
    },
    SE: {
      cta: 'homepage.hero.button.without_days',
      img: {
        homepage: {
          use_future_hero: false,
          hero_desktop: 'web/img/home/hero/se/hero-se-desktop.jpg',
          hero_mobile: 'web/img/home/hero/se/hero-se-mobile.jpg',
          hero_tablet: 'web/img/home/hero/se/hero-se-tablet.jpg',
          want_more_desktop: 'web/img/home/hero/se/hero-se-desktop.jpg',
          want_more_mobile: 'web/img/home/hero/se/hero-se-mobile.jpg',
          want_more_tablet: 'web/img/home/hero/se/hero-se-tablet.jpg',
          more_about_1: 'web/img/home/more-about-nx/se/1-SE.jpg',
          more_about_2: 'web/img/home/more-about-nx/se/2-SE.jpg',
          more_about_3: 'web/img/home/more-about-nx/se/3-SE.jpg',
          phone_cover: 'web/img/home/phone/se/phonebookcovers_SE.png',
        },
      },
      partners: [
        {
          channel: 'COOP',
          unit: 'mypages.collect_points.unit.coop',
          email: {
            label: 'regfunnel.create_account.input_label_email',
            placeholder: '',
          },
          identifier: {
            errorMessage:
              'campaign.collect_points.funnel.error.wrong_identifyer',
            label: 'global.label.ssn',
            placeholder: '',
            required: true,
            tooltip: 'partner.collect_points.ssn.tooltip',
          },
          image: 'web/img/partners/illustrations/coop.jpg',
          logo: {
            src: 'web/img/partners/logos/coop.png',
            height: 28,
            width: 97,
          },
        },

        {
          channel: 'SJ',
          unit: 'mypages.collect_points.unit.sj',
          email: {
            label: 'regfunnel.create_account.input_label_email',
            placeholder: '',
          },
          identifier: {
            errorMessage:
              'campaign.collect_points.funnel.error.wrong_identifyer',
            label: 'mypages.collect_points.partner_label.sj',
            placeholder: '',
            required: true,
          },
          image: 'web/img/partners/illustrations/sj.jpg',
          logo: {
            src: 'web/img/partners/logos/sj.png',
            height: 38,
            width: 38,
          },
        },

        {
          channel: 'KJELL',
          unit: 'mypages.collect_discount.unit.kjell',
          collect_body: 'partner.collect_points.body.kjell',
          collect_offer_details: 'campaign.collect_points.funnel.offer.kjell',
          email: {
            label: 'regfunnel.create_account.input_label_email',
            placeholder: '',
          },
          identifier: {
            errorMessage:
              'campaign.collect_points.funnel.error.wrong_identifyer',
            label: 'mypages.collect_discount.partner_label.kjell',
            placeholder: '',
            required: true,
          },
          image: 'web/img/partners/illustrations/kjell.jpg',
          logo: {
            src: 'web/img/partners/logos/kjell.png',
            height: 38,
            width: 158,
          },
        },

        partnerNorwegian,
        partnerKLM,
      ],
    },
  }
}
